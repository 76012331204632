import {
  CONTACT_US,
  CONTACT_US_SUCCESS,
  CLEAR_CONTACT_US_FLAG,
  CONTACT_US_ERROR
} from "../type/ContactType";
// import { REHYDRATE } from "redux-persist/lib/constants";

const initialState = {
  contactSuccess: false,
  contactError: false,
  isContactSubmitLoading: false
};

export default function contactReducer(state = initialState, action) {
  switch (action.type) {
    // case REHYDRATE: {
    //   return { ...action.payload.contactReducer };
    // }
    case CONTACT_US:
      return {
        ...state,
        isContactSubmitLoading: true
      };
    case CONTACT_US_SUCCESS:
      const {
        payload: { data }
      } = action;
      return {
        ...state,
        contactData: data.ContactUs,
        contactSuccess: true,
        contactError: false,
        isContactSubmitLoading: false,
      };
    case CONTACT_US_ERROR:
      return {
        ...state,
        contactSuccess: false,
        contactError: true,
        isContactSubmitLoading: false
      };
    case CLEAR_CONTACT_US_FLAG:
      return {
        ...state,
        contactSuccess: false,
        contactError: false,
        isContactSubmitLoading: false
      };
    default:
      return state;
  }
}
