import {
  ORDER_REQUEST,
  ORDER_SUCCESS,
  ORDER_ERROR,
  CLEAR_ORDER_FLAG
} from "../type/OrderType";
import { Payment } from "../../graphql/Mutations/OrderMutation";

export const paymentRequest = payload => {
  return {
    type: ORDER_REQUEST,
    payload
  };
};

export const paymentSuccess = payload => {
  return {
    type: ORDER_SUCCESS,
    payload
  };
};

export const paymentError = payload => {
  return {
    type: ORDER_ERROR,
    payload
  };
};

export const payment = (values, client) => {
  return dispatch => {
    dispatch(paymentRequest);
    return client
      .mutate({
        variables: values,
        mutation: Payment
      })
      .then(result => dispatch(paymentSuccess(result)))
      .catch(error => dispatch(paymentError(error)));
  };
};

export const clearPaymentFlag = () => {
  return {
    type: CLEAR_ORDER_FLAG
  };
};
