/*eslint-disable*/
import React, { Component, Suspense, lazy } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/styles";
import { bindActionCreators } from "redux";
import { withApollo } from "react-apollo";

const Header = lazy(() => import("components/Header/Header.js"));
const HeaderLinks = lazy(() => import("components/Header/HeaderLinks.js"));
const Footer = lazy(() => import("components/Footer/Footer"));
const SectionVideo = lazy(() => import("views/TestKitDetails/TestKitDetailsSections/SectionVideo"));

const SectionKitDetails = lazy(() =>
  import("views/TestKitDetails/TestKitDetailsSections/SectionKitDetails.js")
);
const SectionParallax = lazy(() =>
  import("views/TestKitDetails/TestKitDetailsSections/SectionParallax.js")
);
const SectionFaq = lazy(() =>
  import("views/TestKitDetails/TestKitDetailsSections/SectionFaq.js")
);
const SectionHowItWorks = lazy(() =>
  import("views/TestKitDetails/TestKitDetailsSections/SectionHowItWorks.js")
);

import styles from "assets/jss/material-kit-pro-react/views/productStyle.js";

import {
  createCart,
  clearCartFlag,
} from "../../redux/actions/OrderCartActions";
import "./TestKitDetails.scss";

class TestKitPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      qty: 1,
      subTotal: 65,
      loggedIn: localStorage.getItem("LoggedIn"),
    };
  }

  static getDerivedStateFromProps(props, state) {
    const { addCartData, success, clearCartFlag } = props;
    if (success) {
      clearCartFlag();
      props.history.push("cart", { cartData: addCartData });
    }
    return null;
  }

  addQty = () => {
    const { qty } = this.state;
    this.setState({
      qty: qty + 1,
      subTotal: (qty + 1) * 65,
    });
  };

  decreaseQty = () => {
    const { qty } = this.state;
    if (qty > 1) {
      this.setState({
        qty: qty - 1,
        subTotal: (qty - 1) * 65,
      });
    }
  };

  addToCart = () => {
    const { createCart, client } = this.props;
    const { qty, loggedIn } = this.state;

    const addToCartData = {
      product_id: 1,
      quantity: qty,
    };

    // this.props.history.push("cart", { cartData: addToCartData });
    if (loggedIn) {
      createCart(addToCartData, client);
    } else {
      this.props.history.push("/sign-in", {
        fromCartIn: true,
        cartData: addToCartData,
      });
    }
  };

  render() {
    const { classes, isCartLoading } = this.props;
    const { qty, subTotal } = this.state;

    return (
      <>
        <Suspense fallback={<div> Loading... </div>}>
          <div className={classes.productPage} style={{ minHeight: "95vh" }}>
            <Header
              brand="Material Kit PRO React"
              links={<HeaderLinks dropdownHoverColor="rose" />}
              fixed
              color="white"
              changeColorOnScroll={{
                height: 100,
                color: "white",
              }}
            />
            <div className={classes.section} style={{ padding: 0 }}>
              <div
                className={classNames(classes.main, classes.mainRaised)}
                style={{ paddingBottom: 0 }}
              >
                <SectionKitDetails
                  addQty={this.addQty}
                  decreaseQty={this.decreaseQty}
                  qty={qty}
                  addToCart={this.addToCart}
                  isCartLoading={isCartLoading}
                  subTotal={subTotal}
                />
              </div>

              <div
                className={classes.container}
                style={{ marginBottom: "80px" }}
              >
                <div className={classes.videoSection}>
                  <h2 className={`${classes.howItWorks} blood--samples--title`}>
                    How to Collect Blood Sample
                  </h2>
                  <SectionVideo />
                </div>
              </div>

              <SectionParallax />

              <SectionHowItWorks />

              <SectionFaq />

              <Footer />
            </div>
          </div>
        </Suspense>
      </>
    );
  }
}

TestKitPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  addCartData: state.cartReducer && state.cartReducer.addCartData,
  success: state.cartReducer && state.cartReducer.addCartSuccess,
  isCartLoading: state.cartReducer && state.cartReducer.isCartLoading,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      createCart,
      clearCartFlag,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(withStyles(styles)(TestKitPage)));
