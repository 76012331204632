import {
  ADD_TO_CART_REQUEST,
  ADD_TO_CART_SUCCESS,
  ADD_TO_CART_ERROR,
  GET_CART_REQUEST,
  GET_CART_SUCCESS,
  GET_CART_ERROR,
  UPDATE_CART_REQUEST,
  UPDATE_CART_SUCCESS,
  UPDATE_CART_ERROR,
  DELETE_CART_REQUEST,
  DELETE_CART_SUCCESS,
  DELETE_CART_ERROR,
  CLEAR_CART_FLAG
} from "../type/OrderCartType";
import {
  CreateCart,
  UpdateCart,
  DeleteCart
} from "../../graphql/Mutations/OrderCartMutation";
import { Cart } from "../../graphql/Query/OrderCartQuery";

//Create Cart

export const addToCartRequest = payload => {
  return {
    type: ADD_TO_CART_REQUEST,
    payload
  };
};

export const addToCartSuccess = payload => {
  return {
    type: ADD_TO_CART_SUCCESS,
    payload
  };
};

export const addToCartError = payload => {
  return {
    type: ADD_TO_CART_ERROR,
    payload
  };
};

export const createCart = (values, client) => {
  return dispatch => {
    dispatch(addToCartRequest());
    return client
      .mutate({
        variables: values,
        mutation: CreateCart
      })
      .then(result => dispatch(addToCartSuccess(result)))
      .catch(error => dispatch(addToCartError(error)));
  };
};

// Get Cart Details
export const getCartRequest = payload => {
  return {
    type: GET_CART_REQUEST,
    payload
  };
};

export const getCartSuccess = payload => {
  return {
    type: GET_CART_SUCCESS,
    payload
  };
};

export const getCartError = payload => {
  return {
    type: GET_CART_ERROR,
    payload
  };
};

export const getCartDetails = (values, client) => {
  return dispatch => {
    dispatch(getCartRequest);
    return client
      .query({
        variables: values,
        query: Cart,
        fetchPolicy: "no-cache"
      })
      .then(result => dispatch(getCartSuccess(result)))
      .catch(error => dispatch(getCartError(error)));
  };
};

//Update Cart Details

export const updateCartRequest = payload => {
  return {
    type: UPDATE_CART_REQUEST,
    payload
  };
};

export const updateCartSuccess = payload => {
  return {
    type: UPDATE_CART_SUCCESS,
    payload
  };
};

export const updateCartError = payload => {
  return {
    type: UPDATE_CART_ERROR,
    payload
  };
};

export const updateCartDetails = (values, client) => {
  return dispatch => {
    dispatch(updateCartRequest());
    return client
      .mutate({
        variables: values,
        mutation: UpdateCart
      })
      .then(result => dispatch(updateCartSuccess(result)))
      .catch(error => dispatch(updateCartError(error)));
  };
};

//Delete Cart

export const deleteCartRequest = payload => {
  return {
    type: DELETE_CART_REQUEST,
    payload
  };
};

export const deleteCartSuccess = payload => {
  return {
    type: DELETE_CART_SUCCESS,
    payload
  };
};

export const deleteCartError = payload => {
  return {
    type: DELETE_CART_ERROR,
    payload
  };
};

export const deleteCartDetails = (values, client) => {
  return dispatch => {
    dispatch(deleteCartRequest);
    return client
      .mutate({
        variables: values,
        mutation: DeleteCart
      })
      .then(result => dispatch(deleteCartSuccess(result)))
      .catch(error => dispatch(deleteCartError(error)));
  };
};

export const clearCartFlag = () => {
  return {
    type: CLEAR_CART_FLAG
  };
};
