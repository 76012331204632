import { store, persistor } from "./redux/store";
import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router";
import { ApolloProvider } from "react-apollo";
import { Provider } from "react-redux";
import { ApolloClient, InMemoryCache, HttpLink } from "apollo-boost";
import { setContext } from "apollo-link-context";
import "assets/scss/material-kit-pro-react.scss?v=1.8.0";
import { PersistGate } from 'redux-persist/integration/react'

import LoginPage from "views/LoginPage/LoginPage.js";
import PresentationPage from "views/PresentationPage/PresentationPage.js";
import SelfAssessment from "views/SelfAssessment/SelfAssessment";
import SignUp from "views/SignUp/SignUp";
import Questions from "views/Questions/Questions.js";
import Response from "views/Questions/TestResponse.js";
import Packages from "views/Packages/PackageDetails.js";
import Payment from "views/Payment/KitPaymentDetails";
import Assessment from "views/AskAssessment/AskAssessment";
import AboutUs from "views/AboutUs/AboutUs.js";
import TestKitDetails from "views/TestKitDetails/TestKitDetails";
import Cart from "views/TestKitDetails/Cart";
import TestDetails from "views/Questions/TestDetails";
import Licenses from "views/Licenses/Licenses";
import FAQ from "views/Faq/Faq";
import TermsConditions from "views/TermsAndConditions/TermsAndConditions";
import Order from "views/Order/Order";
import Profile from "views/Profile/Profile";

import Blog from "views/Blog/Blog";
import BlogDetails from "views/Blog/BlogDetails";

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_GRAPHQL_CONFIG
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("token");
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ""
    }
  };
});
export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
});

const history = createBrowserHistory();

ReactDOM.render(
  <ApolloProvider client={client}>
    <Provider store={store}>
      <Router history={history}>
        <PersistGate persistor={persistor}>
          <Switch>
            <Route exact path="/sign-in" component={LoginPage} />
            <Route exact path="/self-assessment" component={SelfAssessment} />
            <Route exact path="/sign-up" component={SignUp} />
            <Route exact path="/question" component={Questions} />
            <Route exact path="/response" component={Response} />
            <Route exact path="/packages" component={Packages} />
            <Route exact path="/about-us" component={AboutUs} />
            <Route exact path="/blog" component={Blog} />
            <Route path="/blog/:id" exact component={BlogDetails} />
            <Route exact path="/assessment" component={Assessment} />
            <Route exact path="/payment" component={Payment} />
            <Route exact path="/test-kit-details" component={TestKitDetails} />
            <Route exact path="/cart" component={Cart} />
            <Route exact path="/faq" component={FAQ} />
            <Route exact path="/licenses" component={Licenses} />
            <Route exact path="/order" component={Order} />
            <Route exact path="/terms-conditions" component={TermsConditions} />
            <Route
              exact
              path="/self-assessment-test-details"
              component={TestDetails}
            />
            <Route exact path="/my-profile" component={Profile} />
            <Route exact path="/" component={PresentationPage} />
          </Switch>
        </PersistGate>
      </Router>
    </Provider>
  </ApolloProvider>,
  document.getElementById("root")
);
