/*eslint-disable*/
import React, { Suspense, lazy } from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";

const Header = lazy(() => import("components/Header/Header.js"));
const HeaderLinks = lazy(() => import("components/Header/HeaderLinks.js"));
const Parallax = lazy(() => import("components/Parallax/Parallax.js"));
const Footer = lazy(() => import("components/Footer/Footer.js"));
const Card = lazy(() => import("components/Card/Card.js"));
const CardBody = lazy(() => import("components/Card/CardBody.js"));
const Tabs = lazy(() => import("../PresentationPage/Sections/Tabs"));

import shoppingCartStyle from "assets/jss/material-kit-pro-react/views/shoppingCartStyle.js";

const useStyles = makeStyles(shoppingCartStyle);

export default function PackageDetails() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  return (
    <>
      <Suspense fallback={<div> Loading... </div> }>
        <div>
          <Header
            brand="Material Kit PRO React"
            links={<HeaderLinks dropdownHoverColor="info" />}
            fixed
            color="white"
            changeColorOnScroll={{
              height: 300,
              color: "white",
            }}
          />

          <Parallax
            image={require("assets/img/subscription-banner.jpg")}
            className={classes.parallax}
          />
          <div className={classNames(classes.main, classes.mainRaised)}>
            <div className={classes.container} style={{ paddingBottom: 50 }}>
              <Card plain style={{ marginTop: 0 }}>
                <CardBody plain>
                  <h2 className={classes.title}>
                    Subscription Package Details
                  </h2>
                  <p
                    style={{
                      color: "#999",
                      textAlign: "center",
                      fontSize: "1.2rem",
                      lineHeight: "1.5em",
                    }}
                  >
                    With an individualised hormone replacement therapy and well
                    being programme at Menergy. you will quickly experience a
                    boost in body composition, body density/strength, libido,
                    cognitive capacity. memory, mental energy, stamina, mood and
                    well-being. The in-depth testing that is done at Menergy
                    along with regular follow ups with our experienced patient
                    care coordinators, is part of the secret in creating the
                    shortest route to an improved quality of life.
                  </p>
                  <div className={classes.marginTab}>
                    <Tabs />
                  </div>
                </CardBody>
              </Card>
            </div>
            <Footer />
          </div>
        </div>
      </Suspense>
    </>
  );
}
