/*eslint-disable*/
import React, { lazy, Suspense } from "react";
import { makeStyles } from "@material-ui/core/styles";

const GridContainer = lazy(() => import("components/Grid/GridContainer.js"));
const GridItem = lazy(() => import("components/Grid/GridItem.js"));
const Button = lazy(() => import("components/CustomButtons/Button.js"));
const Card = lazy(() => import("components/Card/Card.js"));
const CardBody = lazy(() => import("components/Card/CardBody.js"));
const Header = lazy(() => import("components/Header/Header"));
const HeaderLinks = lazy(() => import("components/Header/HeaderLinks"));
const EmailDialog = lazy(() => import("./EmailDialog"));
const Img = lazy(() => import("components/Image/Image.js"));

import image from "../../assets/img/online-test-new.svg";
import signupPageStyle from "assets/jss/material-kit-pro-react/views/signupPageStyle.js";

const useStyles = makeStyles(signupPageStyle);

export default function Assessment(props) {
  const [open, setOpen] = React.useState(false);

  const handleDialogOpen = () => {
    setOpen(true);
  };

  const handleDialogClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });

  const goToSelfAssessment = () => {
    props.history.push("/self-assessment");
  };

  const classes = useStyles();
  return (
    <>
      <Suspense fallback={<div> Loading... </div>}>
        <div>
          <Header
            fixed
            color="white"
            links={<HeaderLinks dropdownHoverColor="rose" />}
          />
          <EmailDialog open={open} handleClose={handleDialogClose} {...props} />
          <div
            style={{
              minHeight: "100vh",
              backgroundColor: "white",
            }}
          >
            <div className={classes.container + " " + classes.paddingContainer}>
              <GridContainer justify="center">
                <GridItem xs={12} sm={10} md={8}>
                  <Card
                    className={classes.cardSignup}
                    style={{ boxShadow: "none" }}
                  >
                    <CardBody>
                      <GridContainer justify="center">
                        <GridItem xs={12} sm={12} md={12}>
                          <div>
                            <Img
                              src={image}
                              alt="Menergy - Assessment"
                              className={classes.imageStyleAssessment}
                            />
                            <h2 className={classes.assessmentTitle}>
                              Have you taken self-assessment test?
                            </h2>
                            <div className={classes.flexBtn}>
                              <Button
                                color="rose"
                                onClick={handleDialogOpen}
                                className={classes.askAssessmentButton}
                              >
                                {" "}
                                Yes{" "}
                              </Button>
                              <Button
                                color="rose"
                                onClick={goToSelfAssessment}
                                className={classes.askAssessmentButton}
                              >
                                {" "}
                                No{" "}
                              </Button>
                            </div>
                          </div>
                        </GridItem>
                      </GridContainer>
                    </CardBody>
                  </Card>
                </GridItem>
              </GridContainer>
            </div>
          </div>
        </div>
      </Suspense>
    </>
  );
}
