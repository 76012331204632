/*eslint-disable*/
import React, { lazy, Suspense } from "react";
import { makeStyles } from "@material-ui/core/styles";

const Header = lazy(() => import("components/Header/Header.js"));
const HeaderLinks = lazy(() => import("components/Header/HeaderLinks.js"));
const Footer = lazy(() => import("components/Footer/Footer.js"));
const Parallax = lazy(() => import("components/Parallax/Parallax.js"));
const SectionInterested = lazy(() => import("./SectionInterested"));

import blogPostsPageStyle from "assets/jss/material-kit-pro-react/views/blogPostsPageStyle.js";

const useStyles = makeStyles(blogPostsPageStyle);

export default function BlogPostsPage(props) {
  const classes = useStyles();
  return (
    <div>
      <Suspense fallback={<div> Loading... </div>}>
        <Header
          brand="Material Kit PRO React"
          links={
            <HeaderLinks
              changeColorOnScroll={{
                height: 100,
              }}
              dropdownHoverColor="info"
            />
          }
          fixed
        //   color="transparent"
          changeColorOnScroll={{
            height: 100,
            color: "white",
          }}
        />
        <Parallax
          filter="dark"
          image={require("assets/img/img2.jpg")}
        />
        <div className={classes.main}>
          <div className={classes.container}>
            {/* <SectionPills /> */}
            <SectionInterested {...props} />
          </div>
          {/* <SectionImage /> */}
          {/* <SubscribeLine /> */}
          <Footer />
        </div>
      </Suspense>
    </div>
  );
}