/*eslint-disable*/
import React, { Component, lazy, Suspense } from "react";
import { withStyles } from "@material-ui/core/styles";
import { FormHelperText } from "@material-ui/core";
import { TextField } from "@material-ui/core";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { withApollo } from "react-apollo";
import { bindActionCreators } from "redux";
import GoogleLogin from "react-google-login";
import { isTablet, isMobileOnly } from "react-device-detect";
import { Formik } from "formik";
import * as Yup from "yup";

const Header = lazy(() => import("components/Header/Header.js"));
const HeaderLinks = lazy(() => import("components/Header/HeaderLinks.js"));
const GridContainer = lazy(() => import("components/Grid/GridContainer.js"));
const GridItem = lazy(() => import("components/Grid/GridItem.js"));
const Button = lazy(() => import("components/CustomButtons/Button.js"));
const Card = lazy(() => import("components/Card/Card.js"));
const CardBody = lazy(() => import("components/Card/CardBody.js"));
const Snackbar = lazy(() =>
  import("../../components/Snackbar/SnackbarContent")
);
const Img = lazy(() => import("components/Image/Image.js"));


import styles from "assets/jss/material-kit-pro-react/views/loginPageStyle.js";
import image from "../../assets/img/login/login1.png";
import {
  logIn,
  verifyUser,
  clearLoginFlag,
} from "../../redux/actions/LoginActions";
import ReactFacebookLoginWithButton from "./FacebookButton";
import { socialLogin } from "../../services/SocialLogin";
import {
  createCart,
  clearCartFlag,
} from "../../redux/actions/OrderCartActions";
// import "./LoginPage.scss";
import "assets/scss/material-kit-pro-react.scss"

class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      showError: false,
      fromCartIn: false,
      cartData: props.location.state && props.location.state.cartData,
      openSnackBar: false,
      response: "",
      snackBarVariant: "error",
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    const tokenFromUrl = window.location.href.split("=")[1];

    const { verifyUser } = this.props;
    const token = {
      token: tokenFromUrl,
    };

    if (token && token.token) {
      verifyUser(token);
    }
  }

  static getDerivedStateFromProps(props, prevState) {
    const {
      loginSuccess,
      clearLoginFlag,
      loginData,
      loginError,
      createCart,
      clearCartFlag,
      location: { state },
      client,
      addCartSuccess,
    } = props;
    const { cartData, fromCartIn } = prevState;

    if (loginSuccess) {
      clearLoginFlag();
      localStorage.setItem("LoggedIn", true);
      localStorage.setItem("userName", loginData.user.name);

      if (fromCartIn) {
        clearCartFlag();
        createCart(cartData, client);
      } else {
        props.history.push("/question");
      }
    }

    if (addCartSuccess && fromCartIn) {
      props.history.push("/cart", { fromCart: true });
    }

    if (state && state.hasOwnProperty("fromCartIn") && state.fromCartIn) {
      return {
        cartData: state.cartData,
        fromCartIn: state.fromCartIn,
      };
    }

    if (loginError) {
      clearLoginFlag();
      return {
        showError: true,
      };
    }

    return null;
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  goToWelcomePage = (values) => {
    const { email, password } = values;
    const { logIn, client } = this.props;

    const logInData = {
      email,
      password,
    };
    logIn(logInData, client);
  };

  responseFacebook = (response) => {
    if (response.accessToken) {
      const { fromCartIn, cartData } = this.state;
      const { createCart, clearCartFlag, client } = this.props;
      const data = {
        provider: "facebook",
        token: response.accessToken,
      };
      socialLogin("social-login", data)
        .then((res) => {
          if (res.error) {
            this.setState({
              openSnackBar: true,
              response: "Something went wrong, try again later!",
            });
          } else {
            localStorage.setItem("LoggedIn", true);
            localStorage.setItem("token", res.token);
            localStorage.setItem("userName", res.user.name);
            if (fromCartIn) {
              createCart(cartData, client);
              clearCartFlag();
              this.props.history.push("/cart", { fromCart: true });
            } else {
              this.props.history.push("/question");
            }
          }
        })
        .catch((err) => {
          this.setState({
            openSnackBar: true,
            response: "Something went wrong, try again later!",
          });
        });
    }
  };

  responseGoogle = (response) => {
    const { fromCartIn, cartData } = this.state;
    const { createCart, clearCartFlag, client } = this.props;

    if (response.accessToken) {
      const data = {
        provider: "google",
        token: response.accessToken,
      };
      socialLogin("social-login", data)
        .then((res) => {
          if (res.error) {
            this.setState({
              openSnackBar: true,
              response: "Something went wrong, try again later!",
            });
          } else {
            localStorage.setItem("LoggedIn", true);
            localStorage.setItem("token", res.token);
            localStorage.setItem("userName", res.user.name);
            if (fromCartIn) {
              createCart(cartData, client);
              clearCartFlag();
              this.props.history.push("/cart", { fromCart: true });
            } else {
              this.props.history.push("/question");
            }
          }
        })
        .catch((err) => {
          this.setState({
            openSnackBar: true,
            response: "Something went wrong, try again later!",
          });
        });
    }
  };

  handleCloseSnackbar = () => {
    this.setState({
      openSnackBar: false,
    });
  };

  render() {
    const { classes, isLogInLoading } = this.props;
    const {
      email,
      password,
      openSnackBar,
      response,
      snackBarVariant,
      showError,
    } = this.state;
    return (
      <>
        <Suspense fallback={<div> Loading... </div>}>
          <div>
            <Snackbar
              open={openSnackBar}
              handleClose={this.handleCloseSnackbar}
              variant={snackBarVariant}
              message={response}
            />
            {/* <Header
              fixed
              color="white"
              links={<HeaderLinks dropdownHoverColor="info" />}
            /> */}

            {!isMobileOnly ?
              (<div className={isTablet ? "sign--up--tab--header" : ""}>
                <Header
                  fixed
                  color="white"
                  links={<HeaderLinks dropdownHoverColor="rose" />}
                />
              </div>
              ) : (
                <div
                  className="sign--up--mobile--header">
                  <Header
                    fixed
                    color="white"
                    links={<HeaderLinks dropdownHoverColor="rose" />
                    }
                  />
                </div>
              )}

            {!isMobileOnly ?
              (
                <div className={isTablet ? "login--signup--tab--section" : ""}>
                <div className={`${classes.loginMainDiv} login--main--div`}>
                  <div className={`${classes.container} login--main--container`}>
                    <GridContainer justify="center" className="selfAssessmentForm">
                      <GridItem xs={12} sm={12} md={8} lg={8} className={isTablet ? "login--left--img--grid login--tab--left--img--grid" : "login--left--img--grid"}>
                        <div className="login--left--img">
                          <Img
                            src={image}
                            className={classes.loginImage}
                            alt="login"
                          />
                        </div>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4} lg={4} className="login--right--section--grid">
                        <h2 className={`${classes.cardTitle} login--main--title`}>Sign In</h2>
                        <Card className={`${classes.cardDiv} login--main--content`}>
                          <form className={classes.form}>
                            <Formik
                              enableReinitialize={true}
                              validationSchema={Yup.object().shape({
                                email: Yup.string()
                                  .email("Email is invalid")
                                  .required("Email is required"),
                                password: Yup.string().required(
                                  "Password is required"
                                ),
                              })}
                              initialValues={{
                                email,
                                password,
                              }}
                              onSubmit={(values) => {
                                this.goToWelcomePage(values);
                              }}
                            >
                              {({
                                errors,
                                values,
                                touched,
                                handleSubmit,
                                handleChange,
                              }) => (
                                  <>
                                    <CardBody signup>
                                      <div className="login--email--div">
                                        <TextField
                                          fullWidth
                                          label={"Email"}
                                          name="email"
                                          type="email"
                                          onChange={handleChange}
                                          autoComplete="email"
                                          margin="normal"
                                          // variant="outlined"
                                          value={values.email}
                                        />
                                        {errors.email && touched.email && (
                                          <FormHelperText error>
                                            {errors.email}
                                          </FormHelperText>
                                        )}
                                      </div>
                                      <div className="login--password--div">
                                        <TextField
                                          fullWidth
                                          label={"Password"}
                                          name="password"
                                          type="password"
                                          onChange={handleChange}
                                          autoComplete="email"
                                          margin="normal"
                                          // variant="outlined"
                                          value={values.password}
                                        />
                                        {errors.password && touched.password && (
                                          <FormHelperText error>
                                            {errors.password}
                                          </FormHelperText>
                                        )}
                                      </div>
                                      {showError && (
                                        <p className={classes.error}>
                                          Invalid Username and Password
                                        </p>
                                      )}
                                    </CardBody>
                                    <div className={`${classes.textCenter} login--btn--div`}>
                                      <Button
                                        color="gradient"
                                        onClick={handleSubmit}                                        
                                      >
                                        {isLogInLoading ? (
                                          <span className="btn-spinner" />
                                        ) : (
                                            "Sign In"
                                          )}
                                      </Button>
                                    </div>
                                  </>
                                )}
                            </Formik>
                            <div className={`${classes.divContent} sign--up--section`}>
                              <span>
                                Did Not Have Any Account?
                            <Link to="/sign-up" style={{ color: "inherit" }} className="sing--up--link">
                                  <span>Click Here </span>
                                </Link>
                              </span>
                            </div>
                            <div className="login--option--div">
                              <p className={`${classes.divContent} or--login--with--text`}>
                                <span>Or Sign In With</span>
                              </p>
                              <div className={classes.socialButtons}>
                                <ul className={classes.socialButtons}>
                                  <li>
                                    <ReactFacebookLoginWithButton
                                      appId={process.env.REACT_APP_FACE_BOOK_APP_ID}
                                      autoLoad={false}
                                      redirectUri={
                                        window.location.protocol +
                                        "//" +
                                        window.location.host +
                                        "/sign-in"
                                      }
                                      fields="name,email,picture"
                                      callback={this.responseFacebook}
                                      // isMobile={false}
                                      cssClass="facebook-button"
                                      icon={
                                        <Button justIcon simple color="facebook">
                                          <i className="fab fa-facebook-f"></i>
                                        </Button>
                                      }
                                    />
                                  </li>
                                  <li>
                                    <GoogleLogin
                                      clientId={process.env.REACT_APP_GMAIL_CLIENT_ID}
                                      autoLoad={false}
                                      render={(renderProps) => (
                                        <Button
                                          justIcon
                                          simple
                                          color="google"
                                          onClick={renderProps.onClick}
                                          disabled={renderProps.disabled}
                                        >
                                          <i className="fab fa-google"></i>
                                        </Button>
                                      )}
                                      buttonText=""
                                      onSuccess={this.responseGoogle}
                                      onFailure={this.responseGoogle}
                                      cookiePolicy={"single_host_origin"}
                                    />
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </form>
                        </Card>
                      </GridItem>
                    </GridContainer>
                  </div>
                </div>
                  </div>
              ) : (
                <div className={`${classes.loginMainDiv} login--main--div login--mobile--main--div`}>
                  <div className={`${classes.container} login--main--container`}>
                    <GridContainer justify="center" className="selfAssessmentForm">
                      <GridItem xs={12} sm={12} md={8} lg={8} className={isTablet ? "login--left--img--grid login--tab--left--img--grid" : "login--left--img--grid"}>
                        <div className="login--left--img">
                          <Img
                            src={image}
                            className={classes.loginImage}
                            alt="login"
                          />
                        </div>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4} lg={4} className="login--right--section--grid">
                        <h2 className={`${classes.cardTitle} login--main--title`}>Sign In</h2>
                        <Card className={`${classes.cardDiv} login--main--content`}>
                          <form className={classes.form}>
                            <Formik
                              enableReinitialize={true}
                              validationSchema={Yup.object().shape({
                                email: Yup.string()
                                  .email("Email is invalid")
                                  .required("Email is required"),
                                password: Yup.string().required(
                                  "Password is required"
                                ),
                              })}
                              initialValues={{
                                email,
                                password,
                              }}
                              onSubmit={(values) => {
                                this.goToWelcomePage(values);
                              }}
                            >
                              {({
                                errors,
                                values,
                                touched,
                                handleSubmit,
                                handleChange,
                              }) => (
                                  <>
                                    <CardBody signup>
                                      <div className="login--email--div">
                                        <TextField
                                          fullWidth
                                          label={"Email"}
                                          name="email"
                                          type="email"
                                          onChange={handleChange}
                                          autoComplete="email"
                                          margin="normal"
                                          // variant="outlined"
                                          value={values.email}
                                        />
                                        {errors.email && touched.email && (
                                          <FormHelperText error>
                                            {errors.email}
                                          </FormHelperText>
                                        )}
                                      </div>
                                      <div className="login--password--div">
                                        <TextField
                                          fullWidth
                                          label={"Password"}
                                          name="password"
                                          type="password"
                                          onChange={handleChange}
                                          autoComplete="email"
                                          margin="normal"
                                          // variant="outlined"
                                          value={values.password}
                                        />
                                        {errors.password && touched.password && (
                                          <FormHelperText error>
                                            {errors.password}
                                          </FormHelperText>
                                        )}
                                      </div>
                                      {showError && (
                                        <p className={classes.error}>
                                          Invalid Username and Password
                                        </p>
                                      )}
                                    </CardBody>
                                    <div className={`${classes.textCenter} login--btn--div`}>
                                      <Button
                                        color="gradient"
                                        onClick={handleSubmit}                                       
                                      >
                                        {isLogInLoading ? (
                                          <span className="btn-spinner" />
                                        ) : (
                                            "Sign In"
                                          )}
                                      </Button>
                                    </div>
                                  </>
                                )}
                            </Formik>
                            <div className={`${classes.divContent} sign--up--section`}>
                              <span>
                                Did Not Have Any Account?
                  <Link to="/sign-up" style={{ color: "inherit" }} className="sing--up--link">
                                  <u>Click Here </u>
                                </Link>
                              </span>
                            </div>
                            <div className="login--option--div">
                              <p className={`${classes.divContent} or--login--with--text`}>
                                <span>Or Sign In With</span>
                              </p>
                              <div className={classes.socialButtons}>
                                <ul className={classes.socialButtons}>
                                  <li>
                                    <ReactFacebookLoginWithButton
                                      appId={process.env.REACT_APP_FACE_BOOK_APP_ID}
                                      autoLoad={false}
                                      redirectUri={
                                        window.location.protocol +
                                        "//" +
                                        window.location.host +
                                        "/sign-in"
                                      }
                                      fields="name,email,picture"
                                      callback={this.responseFacebook}
                                      // isMobile={false}
                                      cssClass="facebook-button"
                                      icon={
                                        <Button justIcon simple color="facebook">
                                          <i className="fab fa-facebook-f"></i>
                                        </Button>
                                      }
                                    />
                                  </li>
                                  <li>
                                    <GoogleLogin
                                      clientId={process.env.REACT_APP_GMAIL_CLIENT_ID}
                                      autoLoad={false}
                                      render={(renderProps) => (
                                        <Button
                                          justIcon
                                          simple
                                          color="google"
                                          onClick={renderProps.onClick}
                                          disabled={renderProps.disabled}
                                        >
                                          <i className="fab fa-google"></i>
                                        </Button>
                                      )}
                                      buttonText=""
                                      onSuccess={this.responseGoogle}
                                      onFailure={this.responseGoogle}
                                      cookiePolicy={"single_host_origin"}
                                    />
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </form>
                        </Card>
                      </GridItem>
                    </GridContainer>
                  </div>
                </div>
              )
            }


          </div>
        </Suspense>
      </>
    );
  }
}

LoginPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    verifySuccess: state.logInReducer.verifySuccess,
    userData: state.logInReducer && state.logInReducer.userData,
    loginSuccess: state.logInReducer && state.logInReducer.loginSuccess,
    loginData: state.logInReducer && state.logInReducer.loginData,
    errorData: state.logInReducer && state.logInReducer.errorData,
    loginError: state.logInReducer && state.logInReducer.loginError,
    addCartSuccess: state.cartReducer && state.cartReducer.addCartSuccess,
    isLogInLoading: state.logInReducer && state.logInReducer.isLogInLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      logIn,
      verifyUser,
      clearLoginFlag,
      createCart,
      clearCartFlag,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(withStyles(styles)(LoginPage)));
