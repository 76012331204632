/*eslint-disable*/
import React, { Component, lazy, Suspense } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withApollo } from "react-apollo";

const GridContainer = lazy(() => import("components/Grid/GridContainer.js"));
const GridItem = lazy(() => import("components/Grid/GridItem.js"));
const Button = lazy(() => import("components/CustomButtons/Button.js"));
const Card = lazy(() => import("components/Card/Card.js"));
const CardBody = lazy(() => import("components/Card/CardBody.js"));
const Header = lazy(() => import("components/Header/Header"));
const HeaderLinks = lazy(() => import("components/Header/HeaderLinks"));
const Footer = lazy(() => import("components/Footer/Footer"));
const Img = lazy(() => import("components/Image/Image.js"));

import styles from "assets/jss/material-kit-pro-react/views/shoppingCartStyle.js";
import Success from "assets/img/order-success.svg";
import { payment } from "../../redux/actions/OrderActions";
import { deleteCartDetails } from "../../redux/actions/OrderCartActions";

class OrderSuccess extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uuid: "",
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;

    this.setState({
      uuid: localStorage.getItem("uuid"),
    });

    const { payment, client } = this.props;
    const paymentData = {
      qty: window.location.href.split(/[=&]/)[1],
      productId: window.location.href.split(/[=&]/)[3],
      invoiceId: window.location.href.split(/[=&]/)[5],
      token: window.location.href.split(/[=&]/)[7],
      PayerID: window.location.href.split(/[=&]/)[9],
    };

    if (paymentData && paymentData.qty) {
      payment(paymentData, client);
    }
  }

  static getDerivedStateFromProps(props, state) {
    const { deleteCartDetails, client } = props;
    const { uuid } = state;

    if (props.isOrderSuccess) {
      deleteCartDetails({ uuid }, client);
      localStorage.removeItem("uuid");
    }
    return null;
  }

  render() {
    const { classes } = this.props;
    return (
      <>
        <Suspense fallback={<div> Loading... </div>}>
          <div>
            <Header
              fixed
              color="white"
              links={<HeaderLinks dropdownHoverColor="rose" />}
            />
            <div className={classes.orderPageDiv}>
              <div className={classes.container} style={{ paddingTop: "12vh" }}>
                <GridContainer justify="center">
                  <GridItem xs={12} sm={10} md={8}>
                    <Card
                      className={classes.cardSignup}
                      style={{ boxShadow: "none" }}
                    >
                      <CardBody className={classes.orderCard}>
                        <GridContainer justify="center">
                          <GridItem xs={12} sm={12} md={12}>
                            <div className={classes.successfulOrder}>
                              <Img src={Success} alt="Menergy" />
                              <h2>Your Order Placed Successfully !</h2>
                              <h5>
                                Your order details and Invoice are sent to your
                                registered Email.
                              </h5>
                            </div>
                          </GridItem>
                          <GridItem xs={12} md={12}>
                            <div className={classes.successfulOrder}>
                              <Button
                                color="rose"
                                onClick={() => {
                                  this.props.history.push("/");
                                }}
                              >
                                Finish
                              </Button>
                            </div>
                          </GridItem>
                        </GridContainer>
                      </CardBody>
                    </Card>
                  </GridItem>
                </GridContainer>
              </div>
            </div>
            <Footer />
          </div>
        </Suspense>
      </>
    );
  }
}
OrderSuccess.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    isOrderSuccess: state.orderReducer && state.orderReducer.isOrderSuccess,
    cartDetails: state.cartReducer && state.cartReducer.cartDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      payment,
      deleteCartDetails,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(withStyles(styles)(OrderSuccess)));
