/*eslint-disable*/
import React, { Component, lazy, Suspense } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { withApollo } from "react-apollo";
import { withStyles } from "@material-ui/core/styles";

const Header = lazy(() => import("components/Header/Header.js"));
const Footer = lazy(() => import("components/Footer/Footer.js"));
const GridContainer = lazy(() => import("components/Grid/GridContainer.js"));
const GridItem = lazy(() => import("components/Grid/GridItem.js"));
const HeaderLinks = lazy(() => import("components/Header/HeaderLinks.js"));
const Parallax = lazy(() => import("components/Parallax/Parallax.js"));
const ProfileTabs = lazy(() => import("./ProfileTabs"));
const Img = lazy(() => import("components/Image/Image.js"));

import UserImage from "assets/img/placeholder.png";
import styles from "assets/jss/material-kit-pro-react/views/profilePageStyle.js";

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDispatched: false,
    };
  }

  dispatch = () => {
    this.setState({
      isDispatched: true,
    });
  };

  render() {
    const { classes, userData } = this.props;
    const imageClasses = classNames(
      classes.imgRaised,
      classes.imgRoundedCircle,
      classes.imgFluid
    );
    // const { isDispatched } = this.state;
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Menergy | Profile</title>
          {/* <link rel="canonical" href="https://www.thegaston.com/events" /> */}
          <meta
            name="description"
            content="Menergy provides medically supervised hormone replacement of testosterone deficiencies."
          />
          <meta
            name="keywords"
            content="Menergy, Test Kit, Covid-19, Allergy Test, Food Sensitivity Test, Vitamin Test, Thyroid Test, Hormonal Test"
          />
          <meta name="og:title" property="og:title" content="The Gaston" />
          <meta
            name="og:description"
            property="og:description"
            content="Menergy provides medically supervised hormone replacement of testosterone deficiencies."
          />
          <meta
            name="og:image"
            property="og:image"
            content="https://healthtest.nceptio.com/static/media/menergy.32d08b29.svg"
          />
        </Helmet>
        <Suspense fallback={<div> Loading... </div>}>
          <div>
            <Header
              color="white"
              links={<HeaderLinks dropdownHoverColor="info" />}
              fixed
              changeColorOnScroll={{
                height: 200,
                color: "white",
              }}
            />
            <Parallax
              image={require("assets/img/img3.jpg")}
              filter="dark"
              className={classes.parallax}
            />
            <div className={classNames(classes.main, classes.mainRaised, classes.profileSection)}>
              <div className={classes.container} style={{ minHeight: "60vh" }}>
                <GridContainer justify="center">
                  <GridItem xs={12} sm={12} md={6}>
                    <div className={classes.profile}>
                      <div>
                        <Img
                          src={UserImage}
                          alt="..."
                          className={imageClasses}
                        />
                      </div>
                      <div className={classes.name}>
                        <h3 className={classes.title}>
                          {" "}
                          {userData && userData.name}
                        </h3>
                      </div>
                    </div>
                  </GridItem>
                </GridContainer>
                <div className={classes.profileTabs}>
                  <ProfileTabs />
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </Suspense>
      </>
    );
  }
}

Profile.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    userData: state.logInReducer && state.logInReducer.loginData.user,
  };
};

export default connect(
  mapStateToProps,
  null
)(withApollo(withStyles(styles)(Profile)));
