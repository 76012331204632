/*eslint-disable*/
import React, { Component, lazy, Suspense } from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { FormHelperText } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import MuiPhoneNumber from "material-ui-phone-number";
import { Formik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withApollo } from "react-apollo";
import { Link } from "react-router-dom";
import GoogleLogin from "react-google-login";

const GridContainer = lazy(() => import("components/Grid/GridContainer.js"));
const GridItem = lazy(() => import("components/Grid/GridItem.js"));
const Button = lazy(() => import("components/CustomButtons/Button.js"));
const Card = lazy(() => import("components/Card/Card.js"));
const CardBody = lazy(() => import("components/Card/CardBody.js"));
const Header = lazy(() => import("components/Header/Header"));
const HeaderLinks = lazy(() => import("components/Header/HeaderLinks"));
const Snackbar = lazy(() =>
  import("../../components/Snackbar/SnackbarContent")
);
const Img = lazy(() => import("components/Image/Image.js"));

import styles from "assets/jss/material-kit-pro-react/views/signupPageStyle.js";
import { signUp, signClearFlag } from "../../redux/actions/SignUpActions";
import image from "../../assets/img/login/login1.png";
import ReactFacebookLoginWithButton from "../LoginPage/FacebookButton";
import { socialLogin } from "../../services/SocialLogin";
import "assets/scss/material-kit-pro-react.scss";
import { isTablet, isMobileOnly } from "react-device-detect";

class SelfAssessment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      name: "",
      password: "",
      phone: "",
      confirmPassword: "",
      response:
        "Menergy account has been created successfully. verification email has been sent to registered Email Id.",
      variant: "success",
      openSnackBar: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }

  static getDerivedStateFromProps(props, state) {
    const { signClearFlag } = props;
    if (props.signUpSuccess) {
      signClearFlag();
      props.history.push("/question");
      return {
        openSnackBar: true,
      };
    }
    return null;
  }

  handleCloseSnackbar = () => {
    this.setState({
      openSnackBar: false,
    });
  };

  submitAssessmentData = (values) => {
    const { signUp, client } = this.props;
    const { name, email, password, confirmPassword, phone } = values;
    const signUpDetails = {
      name,
      email,
      password,
      password_confirm: confirmPassword,
      mobile_no: phone,
    };
    signUp(signUpDetails, client);
  };

  responseFacebook = (response) => {
    if (response.accessToken) {
      const data = {
        provider: "facebook",
        token: response.accessToken,
      };
      socialLogin("social-login", data)
        .then((res) => {
          if (res.error) {
            this.setState({
              openSnackBar: true,
              variant: "error",
              response: "Something went wrong, try again later!",
            });
          } else {
            localStorage.setItem("LoggedIn", true);
            localStorage.setItem("token", res.token);
            localStorage.setItem("userName", res.user.name);
            this.props.history.push("/question");
          }
        })
        .catch((err) => {
          this.setState({
            openSnackBar: true,
            variant: "error",
            response: "Something went wrong, try again later!",
          });
        });
    }
  };

  responseGoogle = (response) => {
    if (response.accessToken) {
      const data = {
        provider: "google",
        token: response.accessToken,
      };
      socialLogin("social-login", data)
        .then((res) => {
          if (res.error) {
            this.setState({
              openSnackBar: true,
              variant: "error",
              response: "Something went wrong, try again later!",
            });
          } else {
            localStorage.setItem("LoggedIn", true);
            localStorage.setItem("token", res.token);
            localStorage.setItem("userName", res.user.name);
            this.props.history.push("/question");
          }
        })
        .catch((err) => {
          this.setState({
            openSnackBar: true,
            variant: "error",
            response: "Something went wrong, try again later!",
          });
        });
    }
  };

  render() {
    const { classes, isSignUpLoading } = this.props;

    const {
      email,
      name,
      password,
      confirmPassword,
      response,
      variant,
      openSnackBar,
      phone,
    } = this.state;

    return (
      <>
        <Suspense fallback={<div> Loading... </div>}>
          <div className={classes.signUpMainCard}>
            <div className="selfAssessmentForm">
              <Snackbar
                open={openSnackBar}
                handleClose={this.handleCloseSnackbar}
                variant={variant}
                message={response}
              />
              {!isMobileOnly ?
                (<div className={isTablet ? "sign--up--tab--header" : ""}>
                  <Header
                    absolute
                    color="white"
                    links={<HeaderLinks dropdownHoverColor="rose" />}
                  />
                   </div>
                ) : (
                  <div
                  className="sign--up--mobile--header">
                    <Header
                      absolute
                      color="white"
                      links={<HeaderLinks dropdownHoverColor="rose" />
                      }
                    />
                  </div>
                )}
              {!isMobileOnly ?
                (
                  <div className={`${classes.SignUpMainDiv} login--main--div`}>
                    <div className={`${classes.container} login--main--container`}>
                      <GridContainer justify="center">
                        <GridItem xs={12} sm={12} md={8} lg={8} className={isTablet ? "login--left--img--grid login--tab--left--img--grid" : "login--left--img--grid"}>
                          <div className="login--left--img">
                            <Img
                              src={image}
                              className={classes.loginImage}
                              alt="login"
                            />
                          </div>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4} lg={4} className="login--right--section--grid">
                          <h2 className={`${classes.cardTitle} login--main--title`}>Sign Up</h2>
                          <Card className={`${classes.cardDiv} login--main--content`}>
                            <form className={classes.form}>
                              <Formik
                                enableReinitialize={true}
                                validationSchema={Yup.object().shape({
                                  email: Yup.string()
                                    .email("Email is invalid")
                                    .required("Email is required"),
                                  name: Yup.string().required(
                                    "Name is required"
                                  ),
                                  password: Yup.string().required(
                                    "Password is required"
                                  ),
                                  confirmPassword: Yup.string().required(
                                    "Confirm Password is required"
                                  ),
                                  phone: Yup.string().required(
                                    "Phone number is required"
                                  ),
                                })}
                                initialValues={{
                                  email,
                                  name,
                                  password,
                                  confirmPassword,
                                  phone,
                                }}
                                onSubmit={(values) => {
                                  this.submitAssessmentData(values);
                                }}
                              >
                                {({
                                  errors,
                                  values,
                                  touched,
                                  handleSubmit,
                                  handleChange,
                                }) => (
                                    <>
                                      {/* <div className={classes.SignUpFormDiv1}> */}
                                      <CardBody signup>
                                        <div className="signup--name--div">
                                          <TextField
                                            fullWidth
                                            label={"Full Name"}
                                            // placeholder={"Full Name"}
                                            name="name"
                                            margin="normal"
                                            // variant="outlined"
                                            value={values.name}
                                            onChange={handleChange}
                                          />
                                          {errors.name && touched.name && (
                                            <FormHelperText error>
                                              {errors.name}
                                            </FormHelperText>
                                          )}
                                        </div>

                                        <div className="signup--email--div">
                                          <TextField
                                            fullWidth
                                            label={"Email"}
                                            // placeholder={"Email"}
                                            name="email"
                                            type="email"
                                            autoComplete="email"
                                            margin="normal"
                                            // variant="outlined"
                                            onChange={handleChange}
                                            value={values.email}
                                          />
                                          {errors.email && touched.email && (
                                            <FormHelperText error>
                                              {errors.email}
                                            </FormHelperText>
                                          )}
                                        </div>

                                        <div className="signup--password--div">
                                          <TextField
                                            fullWidth
                                            label={"Password"}
                                            // placeholder={"Password"}
                                            name="password"
                                            type="password"
                                            autoComplete="new-password"
                                            margin="normal"
                                            // variant="outlined"
                                            onChange={handleChange}
                                            value={values.password}
                                          />
                                          {errors.password &&
                                            touched.password && (
                                              <FormHelperText error>
                                                {errors.password}
                                              </FormHelperText>
                                            )}
                                        </div>

                                        <div className="signup--confirm--password--div">
                                          <TextField
                                            fullWidth
                                            label={"Confirm Password"}
                                            // placeholder={"Confirm Password"}
                                            name="confirmPassword"
                                            type="password"
                                            autoComplete="new-password"
                                            margin="normal"
                                            // variant="outlined"
                                            onChange={handleChange}
                                            value={values.confirmPassword}
                                          />
                                          {errors.confirmPassword &&
                                            touched.confirmPassword && (
                                              <FormHelperText error>
                                                {errors.confirmPassword}
                                              </FormHelperText>
                                            )}
                                        </div>


                                        <div className="signup--phone--div">
                                          <MuiPhoneNumber
                                            fullWidth
                                            name="phone"
                                            // variant="outlined"
                                            onBlur={handleChange}
                                            onChange={handleChange}
                                            defaultCountry={"gb"}
                                            // label={"Mobile Phone Number"}
                                            value={values.phone}
                                          />
                                          {errors.phone && touched.phone && (
                                            <FormHelperText error>
                                              {errors.phone}
                                            </FormHelperText>
                                          )}
                                        </div>
                                      </CardBody>
                                      <div className={`${classes.textCenter} login--btn--div`}>
                                        <Button
                                          onClick={handleSubmit}
                                          variant="contained"
                                          color="gradient"                                        
                                        >
                                          {isSignUpLoading ? (
                                            <span className="btn-spinner" />
                                          ) : (
                                              "Sign Up"
                                            )}
                                        </Button>
                                      </div>
                                      {/* </div> */}
                                    </>
                                  )}
                              </Formik>
                              <div className={`${classes.divContent} sign--up--section`}>
                                <span>
                                  Already have account?
                            <Link to="/sign-in" style={{ color: "inherit" }} className="sing--up--link">
                                    <span>Sign In</span>
                                  </Link>
                                </span>
                              </div>
                              <div className="login--option--div">
                                <p className={`${classes.divContent} or--login--with--text`}>
                                  <span>Or Log in With</span>
                                </p>
                                <div className={classes.socialButtons}>
                                  <ul className={classes.socialButtons}>
                                    <li>
                                      <ReactFacebookLoginWithButton
                                        appId={
                                          process.env.REACT_APP_FACE_BOOK_APP_ID
                                        }
                                        autoLoad={false}
                                        redirectUri={
                                          window.location.protocol +
                                          "//" +
                                          window.location.host +
                                          "/sign-in"
                                        }
                                        fields="name,email,picture"
                                        callback={this.responseFacebook}
                                        // isMobile={false}
                                        cssClass="facebook-button"
                                        icon={
                                          <Button
                                            justIcon
                                            simple
                                            color="facebook"
                                          >
                                            <i className="fab fa-facebook-f"></i>
                                          </Button>
                                        }
                                      />
                                    </li>
                                    <li>
                                      <GoogleLogin
                                        clientId={
                                          process.env.REACT_APP_GMAIL_CLIENT_ID
                                        }
                                        autoLoad={false}
                                        render={(renderProps) => (
                                          <Button
                                            justIcon
                                            simple
                                            color="google"
                                            onClick={renderProps.onClick}
                                            disabled={renderProps.disabled}
                                          >
                                            <i className="fab fa-google"></i>
                                          </Button>
                                        )}
                                        buttonText=""
                                        onSuccess={this.responseGoogle}
                                        onFailure={this.responseGoogle}
                                        cookiePolicy={"single_host_origin"}
                                      />
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </form>
                          </Card>
                        </GridItem>
                      </GridContainer>

                    </div>
                  </div>
                ) : (
                  <div className={`${classes.SignUpMainDiv} login--main--div login--mobile--main--div`}>
                    <div className={`${classes.container} login--main--container`}>
                      <GridContainer justify="center">
                        <GridItem xs={12} sm={12} md={8} lg={8} className={isTablet ? "login--left--img--grid login--tab--left--img--grid" : "login--left--img--grid"}>
                          <div className="login--left--img">
                            <Img
                              src={image}
                              className={classes.loginImage}
                              alt="login"
                            />
                          </div>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4} lg={4} className="login--right--section--grid">
                          <h2 className={`${classes.cardTitle} login--main--title`}>Sign Up</h2>
                          <Card className={`${classes.cardDiv} login--main--content`}>
                            <form className={classes.form}>
                              <Formik
                                enableReinitialize={true}
                                validationSchema={Yup.object().shape({
                                  email: Yup.string()
                                    .email("Email is invalid")
                                    .required("Email is required"),
                                  name: Yup.string().required(
                                    "Name is required"
                                  ),
                                  password: Yup.string().required(
                                    "Password is required"
                                  ),
                                  confirmPassword: Yup.string().required(
                                    "Confirm Password is required"
                                  ),
                                  phone: Yup.string().required(
                                    "Phone number is required"
                                  ),
                                })}
                                initialValues={{
                                  email,
                                  name,
                                  password,
                                  confirmPassword,
                                  phone,
                                }}
                                onSubmit={(values) => {
                                  this.submitAssessmentData(values);
                                }}
                              >
                                {({
                                  errors,
                                  values,
                                  touched,
                                  handleSubmit,
                                  handleChange,
                                }) => (
                                    <>
                                      {/* <div className={classes.SignUpFormDiv1}> */}
                                      <CardBody signup>
                                        <div className="signup--name--div">
                                          <TextField
                                            fullWidth
                                            label={"Full Name"}
                                            // placeholder={"Full Name"}
                                            name="name"
                                            margin="normal"
                                            // variant="outlined"
                                            value={values.name}
                                            onChange={handleChange}
                                          />
                                          {errors.name && touched.name && (
                                            <FormHelperText error>
                                              {errors.name}
                                            </FormHelperText>
                                          )}
                                        </div>

                                        <div className="signup--email--div">
                                          <TextField
                                            fullWidth
                                            label={"Email"}
                                            // placeholder={"Email"}
                                            name="email"
                                            type="email"
                                            autoComplete="email"
                                            margin="normal"
                                            // variant="outlined"
                                            onChange={handleChange}
                                            value={values.email}
                                          />
                                          {errors.email && touched.email && (
                                            <FormHelperText error>
                                              {errors.email}
                                            </FormHelperText>
                                          )}
                                        </div>

                                        <div className="signup--password--div">
                                          <TextField
                                            fullWidth
                                            label={"Password"}
                                            // placeholder={"Password"}
                                            name="password"
                                            type="password"
                                            autoComplete="new-password"
                                            margin="normal"
                                            // variant="outlined"
                                            onChange={handleChange}
                                            value={values.password}
                                          />
                                          {errors.password &&
                                            touched.password && (
                                              <FormHelperText error>
                                                {errors.password}
                                              </FormHelperText>
                                            )}
                                        </div>

                                        <div className="signup--confirm--password--div">
                                          <TextField
                                            fullWidth
                                            label={"Confirm Password"}
                                            // placeholder={"Confirm Password"}
                                            name="confirmPassword"
                                            type="password"
                                            autoComplete="new-password"
                                            margin="normal"
                                            // variant="outlined"
                                            onChange={handleChange}
                                            value={values.confirmPassword}
                                          />
                                          {errors.confirmPassword &&
                                            touched.confirmPassword && (
                                              <FormHelperText error>
                                                {errors.confirmPassword}
                                              </FormHelperText>
                                            )}
                                        </div>


                                        <div className="signup--phone--div">
                                          <MuiPhoneNumber
                                            fullWidth
                                            name="phone"
                                            // variant="outlined"
                                            onBlur={handleChange}
                                            onChange={handleChange}
                                            defaultCountry={"gb"}
                                            // label={"Mobile Phone Number"}
                                            value={values.phone}
                                          />
                                          {errors.phone && touched.phone && (
                                            <FormHelperText error>
                                              {errors.phone}
                                            </FormHelperText>
                                          )}
                                        </div>
                                      </CardBody>
                                      <div className={`${classes.textCenter} login--btn--div`}>
                                        <Button
                                          onClick={handleSubmit}
                                          variant="contained"
                                          color="gradient"                                          
                                        >
                                          {isSignUpLoading ? (
                                            <span className="btn-spinner" />
                                          ) : (
                                              "Sign Up"
                                            )}
                                        </Button>
                                      </div>
                                      {/* </div> */}
                                    </>
                                  )}
                              </Formik>
                              <div className={`${classes.divContent} sign--up--section`}>
                                <span>
                                  Already have account?
                            <Link to="/sign-in" style={{ color: "inherit" }} className="sing--up--link">
                                    <u>Sign In</u>
                                  </Link>
                                </span>
                              </div>
                              <div className="login--option--div">
                                <p className={`${classes.divContent} or--login--with--text`}>
                                  <span>Or Log in With</span>
                                </p>
                                <div className={classes.socialButtons}>
                                  <ul className={classes.socialButtons}>
                                    <li>
                                      <ReactFacebookLoginWithButton
                                        appId={
                                          process.env.REACT_APP_FACE_BOOK_APP_ID
                                        }
                                        autoLoad={false}
                                        redirectUri={
                                          window.location.protocol +
                                          "//" +
                                          window.location.host +
                                          "/sign-in"
                                        }
                                        fields="name,email,picture"
                                        callback={this.responseFacebook}
                                        // isMobile={false}
                                        cssClass="facebook-button"
                                        icon={
                                          <Button
                                            justIcon
                                            simple
                                            color="facebook"
                                          >
                                            <i className="fab fa-facebook-f"></i>
                                          </Button>
                                        }
                                      />
                                    </li>
                                    <li>
                                      <GoogleLogin
                                        clientId={
                                          process.env.REACT_APP_GMAIL_CLIENT_ID
                                        }
                                        autoLoad={false}
                                        render={(renderProps) => (
                                          <Button
                                            justIcon
                                            simple
                                            color="google"
                                            onClick={renderProps.onClick}
                                            disabled={renderProps.disabled}
                                          >
                                            <i className="fab fa-google"></i>
                                          </Button>
                                        )}
                                        buttonText=""
                                        onSuccess={this.responseGoogle}
                                        onFailure={this.responseGoogle}
                                        cookiePolicy={"single_host_origin"}
                                      />
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </form>
                          </Card>
                        </GridItem>
                      </GridContainer>

                    </div>
                  </div>
                )
              }

            </div>
          </div>
        </Suspense>
      </>
    );
  }
}
SelfAssessment.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    signUpSuccess: state.signUpReducer && state.signUpReducer.signUpSuccess,
    verifySuccess: state.logInReducer && state.logInReducer.verifySuccess,
    isSignUpLoading: state.signUpReducer && state.signUpReducer.isSignUpLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      signUp,
      signClearFlag,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(withStyles(styles)(SelfAssessment)));
