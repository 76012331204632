import order from "assets/img/order-white.svg";
import receive from "assets/img/delivery.svg";
import sent from "assets/img/exchange-white.svg";
import result from "assets/img/medical-result.svg";
import help from "assets/img/hospital.svg";

export const MailId = "info@menergy.com";

export const AboutUSDesc = [
  {
    desc:
      "Menergy provides medically supervised hormone replacement of testosterone deficiencies. The fact that each individual is unique makes the development of an individualised hormone replacement therapy essential for best results.",
  },
  {
    desc:
      "With an individualised hormone replacement therapy and the wellbeing programme, you will experience reversal of your symptoms such a boost in stamina, mood, improve concentrations, memory and libido. All the above will improve your quality of life.",
  },
  {
    desc:
      "The in-depth home blood testing at Menergy along with regular follow ups with our clinically experienced physicians, is part of the secret in creating the most efficient pathway to an improved wellbeing.",
  },
  {
    desc:
      "Wellness is our craft and we never stop looking after your heath. We are transparent, ethical and passionate about what we do. We at Menergy, put you first and strive to provide the best holistic care.",
  },
];

export const Features = [
  {
    title: "Order Test Kit",
    image: order,
    feature: "Order your home test kit to check your baseline blood levels.",
  },
  {
    title: "Receive Test Kit",
    image: receive,
    feature: "You will receive your home test kit within 1-2 working days.",
  },
  {
    title: "Return Test Kit",
    image: sent,
    feature:
      " Simply return your home test kit to our laboratory Monday-Thursday as soon as the samples collected (return in envelope provided).",
  },
  {
    title: "Receive Results",
    image: result,
    feature:
      "Once your blood tests are processed, confidential results will be available from your secure online account.",
  },
  {
    title: "Medical Support",
    image: help,
    feature:
      " Receive your individualised treatment and wellbeing consultation from our dedicated physician by encrypted chat, phone, or video.",
  },
];

export const Options = [
  {
    title: "None",
    value: "1",
  },
  // {
  //   title: "Mild",
  //   value: "2",
  // },
  // {
  //   title: "Moderate",
  //   value: "3",
  // },
  // {
  //   title: "Severe",
  //   value: "4",
  // },
  // {
  //   title: "Extremely Severe",
  //   value: "5",
  // },
];

export const Links = [
  {
    color: "twitter",
    class: "fab fa-twitter",
  },
  {
    color: "facebook",
    class: "fab fa-facebook-square",
  },
  {
    color: "dribbble",
    class: "fab fa-dribbble",
  },
  {
    color: "google",
    class: "fab fa-google-plus-g",
  },
];

export const TeamLinks = [
  {
    color: "twitter",
    class: "fab fa-twitter",
  },
  {
    color: "facebook",
    class: "fab fa-facebook-square",
  },
  {
    color: "dribbble",
    class: "fab fa-dribbble",
  },
];

export const symptoms = [
  {
    question: "Fever, chills, or sweating",
  },
  {
    question: "Difficulty breathing (not severe)",
  },
  {
    question: "New or worsening cough",
  },
  {
    question: "Sore throat",
  },
  {
    question: "Aching throughout the body",
  },
  {
    question: "Vomiting or diarrhea",
  },
  {
    question: "None of the above",
  },
];

export const symptomsList = [
  {
    question: " Moderate to serve asthma or chronic lung disease",
  },
  {
    question: "Cancer treatment or medicines causing immune suppression",
  },
  {
    question: "Inherited immune system deficiencies or HIV",
  },
  {
    question:
      "Serious heart conditions, such as heart failure of prior heart attack",
  },
  {
    question: "Diabetes ith complications",
  },
  {
    question: "Kidney failure that needs dialysis",
  },
  {
    question: "cirrhosis of th liver",
  },
  {
    question: "Diseases or conditions that make it harder to cough",
  },
  {
    question: "Extreme obesity",
  },
  {
    question: "Pregnancy",
  },
  {
    question: "None of the above",
  },
];

export const travelQuestion = [
  {
    question: "I have traveled internationally",
  },
  {
    question: "I have not traveled internationally",
  },
];

export const visitHistory = [
  {
    question: "I live in an area where COVID-19 is widespread",
  },
  {
    question: "I have visited an area where COVID-19 is widespread",
  },
  {
    question: "I don't know",
  },
  {
    question: "None of the above",
  },
];

export const exposureHistory = [
  {
    question: "I live with someone who has COVID-19",
  },
  {
    question: "I've had close contact with someone who has COVID-19",
  },
  {
    question: "I have been near someone who has COVID-19",
  },
  {
    question: "I've had no exposure",
  },
  {
    question: "I don't know",
  },
];

export const liveInCare = [
  {
    question: "I Live in a long-term care facility",
  },
  {
    question: "No, I don't live in a long-term care facility",
  },
];

export const medicalFacility = [
  {
    question:
      "I have worked in a hospital or other care facility in the past 14 days",
  },
  {
    question:
      "I plan to work in a hospital or other care facility in the next 14 days",
  },
  {
    question: "No, i don't work or plan to work in a care facility",
  },
];

export const Testimonials = [
  {
    userImage: require("assets/img/testimonial/user1.jpeg"),
    name: "Alec Thompson",
    designation: "Alecthompson",
    desc:
      " Your product, The kit that I have purchased from your site and used it that help me overcome the deficiency..!.",
  },
  {
    userImage: require("assets/img/testimonial/user2.jpeg"),
    name: "Gina Andrew",
    designation: "Ginaandrew",
    desc:
      "Your product, The kit that I have purchased from your site and used it that help me overcome the deficiency..! Very useful in busy life..",
  },
  {
    userImage: require("assets/img/testimonial/user3.jpeg"),
    name: "George West",
    designation: "Georgewest",
    desc:
      " Your product, The kit that I have purchased from your site and used it that help me overcome the deficiency..!",
  },
  {
    userImage: require("assets/img/testimonial/user4.jpeg"),
    name: "Alec Thompson",
    designation: "Alecthompson",
    desc:
      " Your product, The kit that I have purchased from your site and used it that help me overcome the deficiency..!",
  },
  {
    userImage: require("assets/img/testimonial/user5.jpeg"),
    name: "Gina Andrew",
    designation: "Ginaandrew",
    desc:
      "Your product, The kit that I have purchased from your site and used it that help me overcome the deficiency..! Very useful in busy life..",
  },
  {
    userImage: require("assets/img/testimonial/user6.jpeg"),
    name: "George West",
    designation: "Georgewest",
    desc:
      " Your product, The kit that I have purchased from your site and used it that help me overcome the deficiency..!",
  },
];

export const TestKit = [
  {
    testImage: require("assets/img/test/allergy1.jpg"),
    title: "Allergy Test Kit",
    desc:
      "Menergy provides all types of Allergy Test Kits like Indoor Allergy Test Kit and Outdoor allergy Test Kit.",
  },
  {
    testImage: require("assets/img/test/covid3.jpg"),
    title: "Covid-19 Test Kit",
    desc: "Menergy provides COVID-19 Test Kit for Corona virus test.",
  },
  {
    testImage: require("assets/img/test/food4.jpg"),
    title: "Food Sensitivity Test Kit",
    desc: "Menergy provides all type of Food Sensitivity Test Kit.",
  },
  {
    testImage: require("assets/img/test/vitamin2.jpg"),
    title: "Vitamin Test Kit",
    desc: "Menergy provides all types of Vitamins Test Kits.",
  },
  {
    testImage: require("assets/img/test/thyroid1.jpg"),
    title: "Thyroid Test Kit",
    desc: "The Menergy provides Thyroid Test Kit.",
  },
  {
    testImage: require("assets/img/test/hormonal2.jpg"),
    title: "Hormonal Test Kit",
    desc: "Menergy provides all type of Sexual and Hormonal Test Kit.",
  },
];

export const VideoData = [
  {
    value: "Lorem Ipsum Dolor Sit Amet, consectetur adipiscing elit",
  },
  {
    value: "Lorem Ipsum Dolor Sit Amet, consectetur adipiscing elit",
  },
  {
    value: "Lorem Ipsum Dolor Sit Amet, consectetur adipiscing elit",
  },
  {
    value: "Lorem Ipsum Dolor Sit Amet, consectetur adipiscing elit",
  },
  {
    value: "Lorem Ipsum Dolor Sit Amet, consectetur adipiscing elit",
  },
];
