/*eslint-disable*/
import React, { lazy, Suspense } from "react";
import { makeStyles } from "@material-ui/core/styles";

const GridContainer = lazy(() => import("components/Grid/GridContainer.js"));
const GridItem = lazy(() => import("components/Grid/GridItem.js"));
const Card = lazy(() => import("components/Card/Card.js"));
const CardHeader = lazy(() => import("components/Card/CardHeader.js"));
const CardBody = lazy(() => import("components/Card/CardBody.js"));
const CardFooter = lazy(() => import("components/Card/CardFooter.js"));
const Button = lazy(() => import("components/CustomButtons/Button.js"));
const Muted = lazy(() => import("components/Typography/Muted.js"));
const Img = lazy(() => import("components/Image/Image.js"));

import cardProfile1 from "assets/img/card-profile1-square.jpg";
import cardProfile2 from "assets/img/card-profile2-square.jpg";
import cardProfile4 from "assets/img/card-profile4-square.jpg";
import { TeamLinks } from "constants/Data";
import teamsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/teamsStyle.js";

const useStyles = makeStyles(teamsStyle);

export default function SectionTeams() {
  const classes = useStyles();
  return (
    <>
      <Suspense>
        <div className="cd-section">
          {/* Team 2 START */}
          <div className={classes.team}>
            <div className={classes.container}>
              <GridContainer>
                <GridItem
                  xs={12}
                  sm={8}
                  md={8}
                  className={
                    classes.mlAuto +
                    " " +
                    classes.mrAuto +
                    " " +
                    classes.textCenter
                  }
                >
                  <h2 className={classes.title}>Our Team</h2>
                  <p className={classes.description}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                  </p>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={4} md={4}>
                  <Card plain profile>
                    <CardHeader image plain>
                      {/* <a> */}
                      <Img src={cardProfile1} alt="..." />
                      {/* </a> */}
                      {/* <div
                        className={classes.coloredShadow}
                        style={{
                          backgroundImage: `url(${cardProfile1})`,
                          opacity: "1",
                        }}
                      /> */}
                    </CardHeader>
                    <CardBody plain>
                      <h4 className={classes.cardTitle}>Lorem Ipsum</h4>
                      <Muted>
                        <p className={classes.cardCategory}>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit
                        </p>
                      </Muted>
                    </CardBody>
                    <CardFooter
                      profile
                      plain
                      className={classes.justifyContent}
                    >
                      {TeamLinks.map((link, index) => (
                        <Button
                          key={link + index}
                          justIcon
                          round
                          color={link.color}
                          className={classes.teamSocialButtons}
                        >
                          <i className={link.class} />
                        </Button>
                      ))}
                    </CardFooter>
                  </Card>
                </GridItem>
                <GridItem xs={12} sm={4} md={4}>
                  <Card plain profile>
                    <CardHeader image plain>
                      {/* <a> */}
                      <Img src={cardProfile2} alt="..." />
                      {/* </a> */}
                      {/* <div
                        className={classes.coloredShadow}
                        style={{
                          backgroundImage: `url(${cardProfile2})`,
                          opacity: "1",
                        }}
                      /> */}
                    </CardHeader>
                    <CardBody plain>
                      <h4 className={classes.cardTitle}>Lorem Ipsum</h4>
                      <Muted>
                        <p className={classes.cardCategory}>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit
                        </p>
                      </Muted>
                    </CardBody>
                    <CardFooter
                      profile
                      plain
                      className={classes.justifyContent}
                    >
                      {TeamLinks.map((link, index) => (
                        <Button
                          key={link + index}
                          justIcon
                          round
                          color={link.color}
                          className={classes.teamSocialButtons}
                        >
                          <i className={link.class} />
                        </Button>
                      ))}
                    </CardFooter>
                  </Card>
                </GridItem>
                <GridItem xs={12} sm={4} md={4}>
                  <Card plain profile>
                    <CardHeader image plain>
                      {/* <a> */}
                      <Img src={cardProfile4} alt="..." />
                      {/* </a> */}
                      {/* <div
                        className={classes.coloredShadow}
                        style={{
                          backgroundImage: `url(${cardProfile4})`,
                          opacity: "1",
                        }}
                      /> */}
                    </CardHeader>
                    <CardBody plain>
                      <h4 className={classes.cardTitle}>Lorem Ipsum</h4>
                      <Muted>
                        <p className={classes.cardCategory}>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit
                        </p>
                      </Muted>
                    </CardBody>
                    <CardFooter
                      profile
                      plain
                      className={classes.justifyContent}
                    >
                      {TeamLinks.map((link, index) => (
                        <Button
                          key={link + index}
                          justIcon
                          round
                          color={link.color}
                          className={classes.teamSocialButtons}
                        >
                          <i className={link.class} />
                        </Button>
                      ))}
                    </CardFooter>
                  </Card>
                </GridItem>
              </GridContainer>
            </div>
          </div>
          {/* Team 2 END */}
        </div>
      </Suspense>
    </>
  );
}
