/*eslint-disable*/
import React, { Component, lazy, Suspense } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/styles";
import { isMobileOnly } from "react-device-detect";
import { connect } from "react-redux";
import { withApollo } from "react-apollo";
import { bindActionCreators } from "redux";

const Header = lazy(() => import("components/Header/Header"));
const HeaderLinks = lazy(() => import("components/Header/HeaderLinks"));
const Footer = lazy(() => import("../../components/Footer/Footer"));
const Snackbar = lazy(() =>
  import("../../components/Snackbar/SnackbarContent")
);
const DesktopCartSection = lazy(() =>
  import("views/TestKitDetails/CartSections/DesktopCartSection.js")
);
const MobileCartSection = lazy(() =>
  import("views/TestKitDetails/CartSections/MobileCartSection.js")
);
const EmptyCartSection = lazy(() =>
  import("views/TestKitDetails/CartSections/EmptyCartSection.js")
);

import styles from "assets/jss/material-kit-pro-react/views/cart/cartStyles";
import "./Cart.scss";
import {
  getCartDetails,
  updateCartDetails,
  deleteCartDetails,
  clearCartFlag,
} from "../../redux/actions/OrderCartActions";
import {
  applyPromoCode,
  clearPromoCodeFlags,
} from "../../redux/actions/PromoActions";
import {
  getPaymentLink,
  clearPaymentFlag,
} from "../../redux/actions/PaymentActions";

class Cart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      qty: 1,
      total: 65,
      price: 65,
      promoCode: "",
      isPromoCodeApplied: false,
      isLoggedIn: false,
      openSnackBar: false,
      response: "",
      snackBarVariant: "error",
      uuid: localStorage.getItem("uuid"),
      promoSuccess: false,
      promoError: false,
      isDeleteCartSuccess: false,
      fromHeaderOrder:
        props.location.state && props.location.state.fromHeaderOrder,
    };
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    const { getCartDetails, client } = this.props;
    const { uuid, fromHeaderOrder } = this.state;
    this.setState(
      {
        isLoggedIn: localStorage.getItem("LoggedIn"),
        uuid: localStorage.getItem("uuid"),
      },
      () => {
        uuid && getCartDetails({ uuid: uuid }, client);
      }
    );
  }

  static getDerivedStateFromProps(props, state) {
    const {
      cartQuantity,
      deleteCartError,
      deleteCartSuccess,
      clearCartFlag,
      updateCartSuccess,
      getCartSuccess,
      updateCart,
      clearPromoCodeFlags,
      promoCodeError,
      promoCodeSuccess,
    } = props;

    if (getCartSuccess) {
      clearCartFlag();
      return {
        qty: cartQuantity,
        total: parseInt(cartQuantity) * 65,
      };
    }
    if (updateCartSuccess) {
      clearCartFlag();
      return {
        qty: updateCart.quantity,
        total: parseInt(updateCart.quantity) * 65,
      };
    }
    if (deleteCartError) {
      clearCartFlag();
      return {
        openSnackBar: true,
      };
    }
    if (deleteCartSuccess) {
      clearCartFlag();
      localStorage.removeItem("uuid");
      return {
        isDeleteCartSuccess: true,
      };
    }

    if (promoCodeSuccess) {
      clearPromoCodeFlags();
      return {
        promoSuccess: true,
      };
    }

    if (promoCodeError) {
      clearPromoCodeFlags();
      return {
        promoError: true,
      };
    }

    return null;
  }

  addQty = () => {
    const { updateCartDetails, client } = this.props;
    const { qty, uuid } = this.state;

    this.setState(
      {
        qty: parseInt(qty) + 1,
        total: parseInt(qty + 1) * 65,
        isIncrease: true,
        isDecrease: false,
      },
      () => {
        const updatedDetails = {
          uuid: uuid,
          product_id: 2,
          quantity: parseInt(qty) + 1,
        };
        updateCartDetails(updatedDetails, client);
      }
    );
  };

  decreaseQty = () => {
    const { updateCartDetails, client } = this.props;
    const { qty, uuid } = this.state;

    if (this.state.qty > 1) {
      this.setState(
        {
          qty: this.state.qty - 1,
          total: (this.state.qty - 1) * 65,
          isDecrease: true,
          isIncrease: false,
        },
        () => {
          const updatedDetails = {
            uuid: uuid,
            product_id: 2,
            quantity: parseInt(qty) - 1,
          };

          updateCartDetails(updatedDetails, client);
        }
      );
    }
  };

  deleteItem = () => {
    const { uuid } = this.state;
    const { deleteCartDetails, client } = this.props;
    deleteCartDetails({ uuid }, client);
  };

  goToPayment = () => {
    const { total, qty, uuid } = this.state;
    this.props.history.push(`/payment`, {
      cartUuid: uuid,
      total: total,
      qty: qty,
    });
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  promoCodeApplied = () => {
    this.setState({
      isPromoCodeApplied: true,
    });
  };

  addPromoCode = () => {
    const { applyPromoCode, client } = this.props;
    const { promoCode, total } = this.state;
    !promoCode &&
      this.setState({
        isNullPromoCode: true,
      });
    const promoCodeData = {
      promo_code: promoCode,
      total_amount: total,
    };
    promoCode && applyPromoCode(promoCodeData, client);
  };

  handleCloseSnackbar = () => {
    this.setState({
      openSnackBar: false,
    });
  };

  cancelButton = () => {
    this.props.history.push("/");
  };

  render() {
    const {
      classes,
      cartQuantity,
      productData,
      isUpdateLoading,
      isPromoCodeLoading,
    } = this.props;
    const {
      qty,
      total,
      price,
      promoCode,
      openSnackBar,
      promoSuccess,
      promoError,
      isDeleteCartSuccess,
      isDecrease,
      isIncrease,
      fromHeaderOrder,
      isNullPromoCode,
    } = this.state;

    return (
      <>
        <Suspense fallback={<div> Loading... </div>}>
          <div style={{ backgroundColor: "white", minHeight: "100vh" }}>
            <Header
              absolute
              color="white"
              links={<HeaderLinks dropdownHoverColor="rose" />}
            />
            <Snackbar
              open={openSnackBar}
              handleClose={this.handleCloseSnackbar}
              variant="error"
              message="Something went wrong, try again later!"
            />
            <div className={`${classes.divFlex} your--order--bg`}>
              <div className={classes.container}>
                {fromHeaderOrder ||
                (!isDeleteCartSuccess && cartQuantity > 0) ? (
                  <>
                    {!isMobileOnly ? (
                      <>
                        <DesktopCartSection
                          addQty={() => this.addQty()}
                          decreaseQty={() => this.decreaseQty()}
                          price={price}
                          isUpdateLoading={isUpdateLoading}
                          qty={qty}
                          total={total}
                          deleteItem={this.deleteItem}
                          promoSuccess={promoSuccess}
                          handleChange={this.handleChange}
                          promoCode={promoCode}
                          addPromoCode={this.addPromoCode}
                          isPromoCodeLoading={isPromoCodeLoading}
                          promoError={promoError}
                          productData={productData}
                          isNullPromoCode={isNullPromoCode}
                          cancelButton={this.cancelButton}
                          goToPayment={this.goToPayment}
                          isDecrease={isDecrease}
                          isIncrease={isIncrease}
                        />
                      </>
                    ) : (
                      <>
                        <MobileCartSection
                          goToPayment={this.goToPayment}
                          addQty={() => this.addQty()}
                          decreaseQty={() => this.decreaseQty()}
                          price={price}
                          qty={qty}
                          total={total}
                          deleteItem={this.deleteItem}
                          promoSuccess={promoSuccess}
                          handleChange={this.handleChange}
                          promoCode={promoCode}
                          addPromoCode={this.addPromoCode}
                          isPromoCodeLoading={isPromoCodeLoading}
                          promoError={promoError}
                          productData={productData}
                          isNullPromoCode={isNullPromoCode}
                        />
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <EmptyCartSection />
                  </>
                )}
              </div>
            </div>
            <Footer />
          </div>
        </Suspense>
      </>
    );
  }
}

Cart.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    cartUuid:
      state.cartReducer &&
      state.cartReducer.cartDetails &&
      state.cartReducer.cartDetails.uuid,
    cartQuantity:
      state.cartReducer &&
      state.cartReducer.cartDetails &&
      state.cartReducer.cartDetails.quantity,
    uuid:
      state.cartReducer &&
      state.cartReducer.addCartData &&
      state.cartReducer.addCartData.uuid,
    getCartSuccess: state.cartReducer && state.cartReducer.getCartSuccess,
    deleteCartSuccess: state.cartReducer && state.cartReducer.deleteCartSuccess,
    deleteCartError: state.cartReducer && state.cartReducer.deleteCartError,
    productData: state.promoCodeReducer && state.promoCodeReducer.productData,
    loggedIn: state.logInReducer && state.logInReducer.loggedIn,
    getLinkSuccess: state.paymentReducer && state.paymentReducer.getLinkSuccess,
    paymentLink: state.paymentReducer && state.paymentReducer.paymentLink,
    updateCartSuccess: state.cartReducer && state.cartReducer.updateCartSuccess,
    updateCart: state.cartReducer && state.cartReducer.updateCart,
    isUpdateLoading: state.cartReducer && state.cartReducer.isUpdateLoading,
    promoCodeSuccess:
      state.promoCodeReducer && state.promoCodeReducer.promoCodeSuccess,
    promoCodeError:
      state.promoCodeReducer && state.promoCodeReducer.promoCodeError,
    isPromoCodeLoading:
      state.promoCodeReducer && state.promoCodeReducer.isPromoCodeLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getCartDetails,
      updateCartDetails,
      deleteCartDetails,
      applyPromoCode,
      getPaymentLink,
      clearPaymentFlag,
      clearCartFlag,
      clearPromoCodeFlags,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(withStyles(styles)(Cart)));
