import {
  CHECK_ASSESSMENT_REQUEST,
  CHECK_ASSESSMENT_SUCCESS,
  CHECK_ASSESSMENT_ERROR,
  CLEAR_CHECK_ASSESSMENT_FLAG
} from "../../redux/type/CheckAssessmentType";
// import { REHYDRATE } from "redux-persist/lib/constants";

const initialState = {
  checkAssessmentSuccess: false,
  checkAssessmentError: false,
  checkAssessmentData: ""
};

export default function checkAssessmentReducer(state = initialState, action) {
  switch (action.type) {
    // case REHYDRATE: {
    //   return { ...action.payload.checkAssessmentReducer };
    // }
    case CHECK_ASSESSMENT_REQUEST:
      return {
        ...state,
        checkAssessmentSuccess: false,
        checkAssessmentError: false
      };
    case CHECK_ASSESSMENT_SUCCESS:
      const {
        payload: { data }
      } = action;
      return {
        ...state,
        checkAssessmentSuccess: true,
        checkAssessmentData: data.CheckSelfAssessment
      };
    case CHECK_ASSESSMENT_ERROR:
      return {
        ...state,
        checkAssessmentSuccess: false,
        checkAssessmentError: true
      };
    case CLEAR_CHECK_ASSESSMENT_FLAG:
      return {
        ...state,
        checkAssessmentSuccess: false,
        checkAssessmentError: false
      };

    default:
      return state;
  }
}
