import {
  secondaryMainColor,
  logoColor,
  secondaryDarkColor,
} from "assets/jss/material-kit-pro-react.js";

const cartStyles = {
  divFlex: {
    paddingTop: "18vh",
    paddingBottom: "10vh",
    display: "flex",
    alignItems: "center",
    "@media (max-width: 768px)": {
      paddingTop: "20vh",
    },
    "@media (min-width: 768px) and (max-width: 1024px)": {
      paddingTop: "10vh",
    },
  },
  container: {
    paddingRight: "15px",
    paddingLeft: "15px",
    marginRight: "auto",
    marginLeft: "auto",
    width: "100%",
    "@media (min-width: 576px)": {
      maxWidth: "540px",
    },
    "@media (min-width: 768px)": {
      maxWidth: "720px",
    },
    "@media (min-width: 992px)": {
      maxWidth: "960px",
    },
    "@media (min-width: 1200px)": {
      maxWidth: "1140px",
    },
  },
  font_size: {
    fontSize: "1rem",
    cursor: "pointer",
  },
  productName: {
    fontWeight: "400",
    color: "#000",
  },
  tableCell: {
    textAlign: "center",
  },
  flexP: {
    display: "flex",
    cursor: "pointer",
  },
  titleCenter: {
    textAlign: "center",
    fontWeight: "600",
    marginBottom: "60px",
    fontSize: "2rem",
  },
  border: {
    borderBottom: "none",
  },
  pb0: {
    paddingBottom: 0,
  },
  customCard: {
    borderRadius: "0px",
    height: "80%",
    "@media (max-width: 1024px)": {
      marginTop: "0px",
    },
  },
  tableOverflow: {
    overflowX: "auto",
  },
  textAlign: {
    textAlign: "right",
  },
  cellTestkit: {
    paddingTop: 0,
    verticalAlign: "top",
  },
  divFlexTitle: {
    display: "flex",
    justifyContent: "space-between",
  },
  paddingRight: {
    paddingRight: 10,
  },
  size: {
    height: "25px !important",
    width: "25px !important",
  },
  tableShadow: {
    padding: "10px",
    boxShadow: "1px 1px 9px 0px #0003",
  },
  iconColor: {
    color: logoColor,
  },
  remove: {
    color: logoColor,
    marginLeft: "5px",
  },
  total: {
    color: secondaryMainColor,
    fontWeight: "500",
  },
  qty: {
    color: secondaryDarkColor,
    fontWeight: "500",
    padding: "10px",
  },
  totalMobile: {
    color: "#455a64",
    fontWeight: "500",
    fontSize: "1rem",
  },
  cartFlex: {
    minHeight: "75vh",
    display: "flex",
    alignItems: "center",
  },
  emptyCart: {
    fontSize: "2rem",
    fontWeight: "400",
  },
  addIntoCart: {
    fontSize: "1rem",
    marginTop: "1.5rem",
    marginBottom: "3rem",
  },
  gridCart: {
    backgroundColor: "#fff",
    padding: "5rem 0",
    boxShadow: "5px 6px 8px 1px #00000024",

    "@media (min-width: 1024px)": {
      marginTop: "80px",
      marginBottom: "80px",
    },
  },
  shoppingButton: {
    fontSize: "0.9rem",
  },
  mobileProductPrice: {
    fontSize: "1.25rem",
    marginTop: "10px",
  },
  mobilePromoCode: {
    padding: "15px 0",
    display: "flex",
    justifyContent: "center",
    color: "green",
    fontWeight: "400",
  },
  desktopPromoCode: {
    display: "flex",
    justifyContent: "center",
    color: "green",
    fontWeight: "400",
  },
  promoAddIcon: {
    marginLeft: "8px",
  },
  promoCodeInput: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  promoCodeButton: {
    padding: "0",
    fontSize: "0.9rem",
  },
  discountMobile: {
    textAlign: "center",
    "& p": {
      fontSize: "1rem",
      fontWeight: "500",
      "& span": {
        fontWeight: "400",
        color: "#455a64",
      },
    },
  },
  discountDesktop: {
    paddingTop: "20px",
    "& p": {
      fontSize: "1rem",
      fontWeight: "500",
      color: "#ed383c",
      "& span": {
        fontWeight: "400",
        color: "#455a64",
      },
    },
  },
  discountedPrice: {
    textDecoration: "line-through",
  },
  priceAfterDiscount: {
    color: "green",
  },  
  buttonMargin: {
    marginLeft: "20px !important",
  },
};

export default cartStyles;
