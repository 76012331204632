/*eslint-disable*/
import React, { Component, lazy, Suspense } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/styles";
import Container from "@material-ui/core/Container";
import FormControl from "@material-ui/core/FormControl";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import "bs-stepper/dist/css/bs-stepper.min.css";
import Stepper from "bs-stepper";

const Button = lazy(() => import("components/CustomButtons/Button.js"));
const Header = lazy(() => import("components/Header/Header"));
const HeaderLinks = lazy(() => import("components/Header/HeaderLinks"));
const Footer = lazy(() => import("../../components/Footer/Footer"));

import {
  symptoms,
  travelQuestion,
  symptomsList,
  visitHistory,
  exposureHistory,
  liveInCare,
  medicalFacility,
} from "constants/Data";
import styles from "assets/jss/material-kit-pro-react/views/questionStyle";
import "../PresentationPage/Presentation.scss";
import { Checkbox } from "@material-ui/core";
import "./Questions.scss";

class Questions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      questionAnswers: [],
      saveQuestion: false,
      openSnackBar: false,
      activeClass: "normal",
      isEmergency: "emergency",
      age: "18",
      symptomsAns: [],
      travelAns: [],
      symptomsListAns: [],
      visitHistoryAns: [],
      exposureHistoryAns: [],
      liveInCareAns: [],
      medicalFacilityAns: [],
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    // this.setState({
    //   questionAnswers: symptoms,
    // });
    this.stepper = new Stepper(document.querySelector("#stepper1"), {
      linear: false,
      animation: true,
    });
  }

  handleEmergencyChange = (event) => {
    this.setState({
      isEmergency: event.target.value,
    });
  };

  handleAgeChange = (e) => {
    this.setState({
      age: e.target.value,
    });
  };

  goToNextPage = () => {
    this.props.history.push("/response");
  };

  render() {
    const {
      questionAnswers,
      isEmergency,
      age,
      symptomsAns,
      travelAns,
      symptomsListAns,
      visitHistoryAns,
      exposureHistoryAns,
      liveInCareAns,
      medicalFacilityAns,
    } = this.state;
    const { classes } = this.props;
    return (
      <>
        <Suspense fallback={<div> Loading... </div>}>
          <div className={classes.bgImage}>
            <div>
              <Header
                fixed
                color="white"
                links={<HeaderLinks dropdownHoverColor="rose" />}
              />
              <Container maxWidth="lg" className={classes.containerDiv}>
                <div className={classes.mainDiv}>
                  <div id="stepper1" className="bs-stepper">
                    <div className="bs-stepper-header">
                      <div className="step" data-target="#test-l-1">
                        <button className="step-trigger question--stepper--btn">
                          <span className="bs-stepper-circle">1</span>
                        </button>
                      </div>
                      <div className="line"></div>
                      <div className="step" data-target="#test-l-2">
                        <button className="step-trigger question--stepper--btn">
                          <span className="bs-stepper-circle">2</span>
                        </button>
                      </div>
                      <div className="line"></div>
                      <div className="step" data-target="#test-l-3">
                        <button className="step-trigger question--stepper--btn">
                          <span className="bs-stepper-circle">3</span>
                        </button>
                      </div>
                      <div className="line"></div>
                      <div className="step" data-target="#test-l-4">
                        <button className="step-trigger question--stepper--btn">
                          <span className="bs-stepper-circle">4</span>
                        </button>
                      </div>
                      <div className="line"></div>
                      <div className="step" data-target="#test-l-5">
                        <button className="step-trigger question--stepper--btn">
                          <span className="bs-stepper-circle">5</span>
                        </button>
                      </div>
                      <div className="line"></div>
                      <div className="step" data-target="#test-l-6">
                        <button className="step-trigger question--stepper--btn">
                          <span className="bs-stepper-circle">6</span>
                        </button>
                      </div>
                      <div className="line"></div>
                      <div className="step" data-target="#test-l-7">
                        <button className="step-trigger question--stepper--btn">
                          <span className="bs-stepper-circle">7</span>
                        </button>
                      </div>
                      <div className="line"></div>
                      <div className="step" data-target="#test-l-8">
                        <button className="step-trigger question--stepper--btn">
                          <span className="bs-stepper-circle">8</span>
                        </button>
                      </div>
                      <div className="line"></div>
                      <div className="step" data-target="#test-l-9">
                        <button className="step-trigger question--stepper--btn">
                          <span className="bs-stepper-circle">9</span>
                        </button>
                      </div>
                    </div>
                    <div className="bs-stepper-content">
                      <form>
                        <div id="test-l-1" className="content">
                          <div
                            className="div-content"
                            style={{ marginTop: "20px", paddingBottom: "40px" }}
                          >
                            <div className="question--tool--first--div">
                              <h3 className="question-stepper-title">
                                COVID-19 Screening Tool
                              </h3>
                              <p className="symptomsDesc">
                                You’ll answer a few questions about symptoms,
                                travel, and contact you’ve had with others.
                              </p>
                              <p className="symptomsDesc">
                                Your answers will not be shared with any portal
                                without your permission.
                              </p>
                            </div>
                            <div className="question--tool--use--btn--div">
                              <Button
                                onClick={() => this.stepper.next()}
                                color="gradient"
                                className="screening--tool--myself--btn"
                              >
                                Use for Myself
                              </Button>
                              <Button
                                onClick={() => this.stepper.next()}
                                color="gradient"
                              >
                                Use for Someone Else
                              </Button>
                            </div>
                          </div>
                        </div>
                        <div id="test-l-2" className="content radio-content">
                          <div>
                            <h3 className="question-stepper-title">
                              Is this an emergency?
                              <p>
                                Stop and call 911 if you are experiencing:
                              </p>
                            </h3>
                            <div
                              className="div-content"
                              style={{ marginBottom: 20 }}
                            >
                              <div>
                                <ul className="symptomsList">
                                  <li>
                                    Severe, constant chest pain or pressure
                                  </li>
                                  <li>Extreme difficulty breathing</li>
                                  <li>Severe, constant lightheadedness</li>
                                  <li>
                                    Serious disorientation or unresponsiveness
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <FormControl component="fieldset">
                              <RadioGroup
                                aria-label="isEmergency"
                                name="isEmergency"
                                value={isEmergency}
                                onChange={this.handleEmergencyChange}
                                className="screening--tool--label--section"
                              >
                                <FormControlLabel
                                  value="emergency"
                                  control={<Radio />}
                                  label="I'm experiencing at least one of these symptoms"
                                />
                                <FormControlLabel
                                  value="casual"
                                  control={<Radio />}
                                  label="I do not have any of these symptoms"
                                />
                              </RadioGroup>
                            </FormControl>
                          </div>
                          <div className="question--screening--button">
                            <Button
                              color="gradient"
                              onClick={() => this.stepper.next()}
                            >
                              Next
                            </Button>
                          </div>
                        </div>
                        <div id="test-l-3" className="content radio-content">
                          <div>
                            <h3 className="question-stepper-title">
                              How old are you?
                            </h3>
                            <FormControl component="fieldset">
                              <RadioGroup
                                aria-label="age"
                                name="age"
                                value={age}
                                onChange={this.handleAgeChange}
                                className="screening--tool--label--section"
                              >
                                <FormControlLabel
                                  value="18"
                                  control={<Radio />}
                                  label="Under 18"
                                />
                                <FormControlLabel
                                  value="20"
                                  control={<Radio />}
                                  label="Between 18 and 64"
                                />
                                <FormControlLabel
                                  value="65"
                                  control={<Radio />}
                                  label="65 or older"
                                />
                              </RadioGroup>
                            </FormControl>
                          </div>
                          <div className="question--screening--button">
                            <Button
                              color="gradient"                            
                              onClick={() => this.stepper.next()}
                            >
                              Next
                            </Button>
                          </div>
                        </div>
                        <div id="test-l-4" className="content">
                          <div className="question--options--screening--tool">
                            <h3 className="question-stepper-title">
                              Are you experiencing any of these symptoms?
                            </h3>
                            {symptoms.map((question, index) => (
                              <div
                                className={classes.margin_bottom}
                                key={question.question + index}
                              >
                                <div className={classes.divQuestions}>
                                  <h4 className={classes.questionDiv}>
                                    {index + 1}. {question.question}
                                  </h4>
                                  <div className={`${classes.scoreCheckBox} question--checkbox--selected--color`}>
                                    <Checkbox
                                      color="default"
                                      checked={symptomsAns[index]}
                                      onChange={(e) => {
                                        (symptomsAns[index] = e.target.checked),
                                          this.setState({
                                            symptomsAns,
                                          });
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                          <div className="question--screening--button">
                            <Button
                              color="gradient"
                              onClick={() => this.stepper.next()}                             
                            >
                              Next
                            </Button>
                          </div>
                        </div>
                        <div id="test-l-5" className="content">
                          <div className="question--options--screening--tool">
                            <h3 className="question-stepper-title">
                              Do any of these apply to you?
                            </h3>
                            {symptomsList.map((question, index) => (
                              <div
                                className={classes.margin_bottom}
                                key={question.question + index}
                              >
                                <div className={classes.divQuestions}>
                                  <h4 className={classes.questionDiv}>
                                    {index + 1}. {question.question}
                                  </h4>
                                  <div className={`${classes.scoreCheckBox} question--checkbox--selected--color`}>
                                    <Checkbox
                                      color="default"
                                      checked={symptomsListAns[index]}
                                      onChange={(e) => {
                                        (symptomsListAns[index] =
                                          e.target.checked),
                                          this.setState({
                                            symptomsListAns,
                                          });
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                          <div className="question--screening--button">
                            <Button
                              color="gradient"
                              onClick={() => this.stepper.next()}                             
                            >
                              Next
                            </Button>
                          </div>
                        </div>
                        <div id="test-l-6" className="content">
                          <div className="question--options--screening--tool">
                            <h3 className="question-stepper-title">
                              In the last 14 days, have you traveled
                              internationally?
                            </h3>
                            {travelQuestion.map((question, index) => (
                              <div
                                className={classes.margin_bottom}
                                key={question.question + index}
                              >
                                <div className={classes.divQuestions}>
                                  <h4 className={classes.questionDiv}>
                                    {index + 1}. {question.question}
                                  </h4>
                                  <div className={`${classes.scoreCheckBox} question--checkbox--selected--color`}>
                                    <Checkbox
                                      color="default"
                                      checked={travelAns[index]}
                                      onChange={(e) => {
                                        (travelAns[index] = e.target.checked),
                                          this.setState({
                                            travelAns,
                                          });
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                          <div className="question--screening--button">
                            <Button
                              color="gradient"
                              onClick={() => {
                                this.stepper.next()
                              }}                             
                            >
                              Next
                            </Button>
                          </div>
                        </div>
                        <div id="test-l-7" className="content">
                          <div className="question--options--screening--tool">
                            <h3 className="question-stepper-title">
                              In the last 14 days, have you been in an area
                              where COVID‑19 is widespread?
                            </h3>
                            {visitHistory.map((question, index) => (
                              <div
                                className={classes.margin_bottom}
                                key={question.question + index}
                              >
                                <div className={classes.divQuestions}>
                                  <h4 className={classes.questionDiv}>
                                    {index + 1}. {question.question}
                                  </h4>
                                  <div className={`${classes.scoreCheckBox} question--checkbox--selected--color`}>
                                    <Checkbox
                                      color="default"
                                      checked={visitHistoryAns[index]}
                                      onChange={(e) => {
                                        (visitHistoryAns[index] =
                                          e.target.checked),
                                          this.setState({
                                            visitHistoryAns,
                                          });
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                          <div className="question--screening--button">
                            <Button
                              color="gradient"
                              onClick={() => {
                                this.stepper.next();
                              }}                             
                            >
                              Next
                            </Button>
                          </div>
                        </div>
                        <div id="test-l-8" className="content">
                          <div className="question--options--screening--tool">
                            <h3 className="question-stepper-title">
                              In the last 14 days, what is your exposure to
                              others who are known to have COVID‑19?
                            </h3>
                            {exposureHistory.map((question, index) => (
                              <div
                                className={classes.margin_bottom}
                                key={question.question + index}
                              >
                                <div className={classes.divQuestions}>
                                  <h4 className={classes.questionDiv}>
                                    {index + 1}. {question.question}
                                  </h4>
                                  <div className={`${classes.scoreCheckBox} question--checkbox--selected--color`}>
                                    <Checkbox
                                      color="default"
                                      checked={exposureHistoryAns[index]}
                                      onChange={(e) => {
                                        (exposureHistoryAns[index] =
                                          e.target.checked),
                                          this.setState({
                                            exposureHistoryAns,
                                          });
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                          <div className="question--screening--button">
                            <Button
                              color="gradient"
                              onClick={() => {
                                this.stepper.next()
                              }}                             
                            >
                              Next
                            </Button>
                          </div>
                        </div>
                        <div id="test-l-8" className="content">
                          <div className="question--options--screening--tool">
                            <h3 className="question-stepper-title">
                              Do you live in a care facility?
                            </h3>
                            {liveInCare.map((question, index) => (
                              <div
                                className={classes.margin_bottom}
                                key={question.question + index}
                              >
                                <div className={classes.divQuestions}>
                                  <h4 className={classes.questionDiv}>
                                    {index + 1}. {question.question}
                                  </h4>
                                  <div className={`${classes.scoreCheckBox} question--checkbox--selected--color`}>
                                    <Checkbox
                                      color="default"
                                      checked={liveInCareAns[index]}
                                      onChange={(e) => {
                                        (liveInCareAns[index] =
                                          e.target.checked),
                                          this.setState({
                                            liveInCareAns,
                                          });
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                          <div className="question--screening--button">
                            <Button
                              color="gradient"
                              onClick={() => {
                                this.stepper.next();
                              }}                             
                            >
                              Next
                            </Button>
                          </div>
                        </div>
                        <div id="test-l-9" className="content">
                          <div className="question--options--screening--tool">
                            <h3 className="question-stepper-title">
                              Do you work in a medical facility?
                            </h3>
                            {medicalFacility.map((question, index) => (
                              <div
                                className={classes.margin_bottom}
                                key={question.question + index}
                              >
                                <div className={classes.divQuestions}>
                                  <h4 className={classes.questionDiv}>
                                    {index + 1}. {question.question}
                                  </h4>
                                  <div className={`${classes.scoreCheckBox} question--checkbox--selected--color`}>
                                    <Checkbox
                                      color="default"
                                      checked={medicalFacilityAns[index]}
                                      onChange={(e) => {
                                        (medicalFacilityAns[index] =
                                          e.target.checked),
                                          this.setState({
                                            medicalFacilityAns,
                                          });
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                          <div className="question--screening--button">
                            <Button
                              color="gradient"
                              onClick={this.goToNextPage}                             
                            >
                              Submit
                            </Button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </Container>
            </div>
            <Footer />
          </div>
        </Suspense>
      </>
    );
  }
}

Questions.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Questions);
