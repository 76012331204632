/*eslint-disable*/
import React, { Component, lazy, Suspense } from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { FormHelperText } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import MuiPhoneNumber from "material-ui-phone-number";
import { Formik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withApollo } from "react-apollo";

const GridContainer = lazy(() => import("components/Grid/GridContainer.js"));
const GridItem = lazy(() => import("components/Grid/GridItem.js"));
const Button = lazy(() => import("components/CustomButtons/Button.js"));
const Card = lazy(() => import("components/Card/Card.js"));
const CardBody = lazy(() => import("components/Card/CardBody.js"));
const Header = lazy(() => import("components/Header/Header"));
const HeaderLinks = lazy(() => import("components/Header/HeaderLinks"));
const Snackbar = lazy(() =>
  import("../../components/Snackbar/SnackbarContent")
);
const Img = lazy(() => import("components/Image/Image.js"));

import image from "../../assets/img/login/login1.png";
import styles from "assets/jss/material-kit-pro-react/views/signupPageStyle.js";
import {
  selfAssessment,
  selfAssessmentClearFlag,
} from "../../redux/actions/SelfAssessmentAction";
import "assets/scss/material-kit-pro-react.scss"
import { isTablet, isMobileOnly } from "react-device-detect";

class SelfAssessment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      name: "",
      phone: "",
      response: "",
      variant: "error",
      openSnackBar: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  }

  static getDerivedStateFromProps(props, state) {
    const {
      selfAssessmentSuccess,
      selfAssessmentClearFlag,
      selfAssessmentError,
    } = props;
    if (selfAssessmentSuccess) {
      selfAssessmentClearFlag();
      props.history.push("/question");
    }
    if (selfAssessmentError) {
      selfAssessmentClearFlag();
      return {
        openSnackBar: true,
        response: "The email has already been taken.",
      };
    }
    return null;
  }

  handleCloseSnackbar = () => {
    this.setState({
      openSnackBar: false,
    });
  };

  submitAssessmentData = (values) => {
    const { selfAssessment, client } = this.props;
    const { name, email, phone } = values;
    const selfAssessmentDetails = {
      name,
      email,
      mobile_no: phone,
    };
    selfAssessment(selfAssessmentDetails, client);
  };

  render() {
    const { classes, isSelfAssessmentLoading } = this.props;

    const { email, name, response, variant, openSnackBar, phone } = this.state;

    return (
      <>
        <Suspense fallback={<div> Loading... </div>}>
          <div className={classes.signUpMainCard}>
            <div className="selfAssessmentForm">
              <Snackbar
                open={openSnackBar}
                handleClose={this.handleCloseSnackbar}
                variant={variant}
                message={response}
              />
              {!isMobileOnly ?
                (<div className={isTablet ? "sign--up--tab--header" : ""}>
                  <Header
                    absolute
                    color="white"
                    links={<HeaderLinks dropdownHoverColor="rose" />}
                  />
                  </div>
                ) : (
                  <div
                    className="sign--up--mobile--header">
                    <Header
                      absolute
                      color="white"
                      links={<HeaderLinks dropdownHoverColor="rose" />
                      }
                    />
                  </div>
                )}

              {!isMobileOnly ?
                (
                  <div className={`${classes.SignUpMainDiv} login--main--div`}>
                    <div className={`${classes.container} login--main--container`}>
                      <GridContainer justify="center">
                        <GridItem xs={12} sm={12} md={8} lg={8} className={isTablet ? "login--left--img--grid login--tab--left--img--grid" : "login--left--img--grid"}>
                          <div className="login--left--img">
                            <Img
                              src={image}
                              className={classes.loginImage}
                              alt="login"
                            />
                          </div>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4} lg={4} className="login--right--section--grid">
                          <h2 className={`${classes.cardTitle} login--main--title`}>Self Assessment</h2>
                          <Card className={`${classes.cardDiv} login--main--content`}>
                            <form className={classes.form}>
                              <Formik
                                enableReinitialize={true}
                                validationSchema={Yup.object().shape({
                                  email: Yup.string()
                                    .required("Email is required")
                                    .email("Email is invalid"),
                                  name: Yup.string().required(
                                    "Name is required"
                                  ),
                                  phone: Yup.string().required(
                                    "Phone number is required"
                                  ),
                                })}
                                initialValues={{
                                  email,
                                  name,
                                  phone,
                                }}
                                onSubmit={(values) => {
                                  // this.submitAssessmentData(values);
                                  this.props.history.push("/question");
                                }}
                              >
                                {({
                                  errors,
                                  values,
                                  touched,
                                  handleSubmit,
                                  handleChange,
                                }) => (
                                    <>
                                      <CardBody signup>
                                        <div className="assessment--name--div">
                                          <TextField
                                            fullWidth
                                            label={"Full Name"}
                                            name="name"
                                            margin="normal"
                                            // variant="outlined"
                                            value={values.name}
                                            onChange={handleChange}
                                          />
                                          {errors.name && touched.name && (
                                            <FormHelperText error>
                                              {errors.name}
                                            </FormHelperText>
                                          )}
                                        </div>

                                        <div className="assessment--email--div">
                                          <TextField
                                            fullWidth
                                            label={"Email"}
                                            name="email"
                                            type="email"
                                            autoComplete="email"
                                            margin="normal"
                                            // variant="outlined"
                                            onChange={handleChange}
                                            value={values.email}
                                          />
                                          {errors.email && touched.email && (
                                            <FormHelperText error>
                                              {errors.email}
                                            </FormHelperText>
                                          )}
                                        </div>

                                        <div className="assessment--phone--div">
                                          <MuiPhoneNumber
                                            fullWidth
                                            name="phone"
                                            // variant="outlined"
                                            disableAreaCodes={true}
                                            onBlur={handleChange}
                                            defaultCountry={"gb"}
                                            onChange={handleChange}
                                            countryCodeEditable={true}
                                            // label={"Mobile Phone Number"}
                                            value={values.phone}
                                          />
                                          {errors.phone && touched.phone && (
                                            <FormHelperText error>
                                              {errors.phone}
                                            </FormHelperText>
                                          )}
                                        </div>
                                      </CardBody>
                                      <div className={`${classes.textCenter} login--btn--div`}>
                                        <Button
                                          onClick={handleSubmit}
                                          color="gradient"
                                          variant="contained"                                       
                                        >
                                          {isSelfAssessmentLoading ? (
                                            <span className="btn-spinner" />
                                          ) : (
                                              "Start Assessment"
                                            )}
                                        </Button>
                                      </div>
                                    </>
                                  )}
                              </Formik>
                            </form>
                          </Card>
                        </GridItem>
                      </GridContainer>

                    </div>
                  </div>
                ) : (
                  <div className={`${classes.SignUpMainDiv} login--main--div login--mobile--main--div`}>
                    <div className={`${classes.container} login--main--container`}>
                      <GridContainer justify="center">
                        <GridItem xs={12} sm={12} md={8} lg={8} className={isTablet ? "login--left--img--grid login--tab--left--img--grid" : "login--left--img--grid"}>
                          <div className="login--left--img">
                            <Img
                              src={image}
                              className={classes.loginImage}
                              alt="login"
                            />
                          </div>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4} lg={4} className="login--right--section--grid">
                          <h2 className={`${classes.cardTitle} login--main--title`}>Self Assessment</h2>
                          <Card className={`${classes.cardDiv} login--main--content`}>
                            <form className={classes.form}>
                              <Formik
                                enableReinitialize={true}
                                validationSchema={Yup.object().shape({
                                  email: Yup.string()
                                    .required("Email is required")
                                    .email("Email is invalid"),
                                  name: Yup.string().required(
                                    "Name is required"
                                  ),
                                  phone: Yup.string().required(
                                    "Phone number is required"
                                  ),
                                })}
                                initialValues={{
                                  email,
                                  name,
                                  phone,
                                }}
                                onSubmit={(values) => {
                                  // this.submitAssessmentData(values);
                                  this.props.history.push("/question");
                                }}
                              >
                                {({
                                  errors,
                                  values,
                                  touched,
                                  handleSubmit,
                                  handleChange,
                                }) => (
                                    <>
                                      <CardBody signup>
                                        <div className="assessment--name--div">
                                          <TextField
                                            fullWidth
                                            label={"Full Name"}
                                            name="name"
                                            margin="normal"
                                            // variant="outlined"
                                            value={values.name}
                                            onChange={handleChange}
                                          />
                                          {errors.name && touched.name && (
                                            <FormHelperText error>
                                              {errors.name}
                                            </FormHelperText>
                                          )}
                                        </div>

                                        <div className="assessment--email--div">
                                          <TextField
                                            fullWidth
                                            label={"Email"}
                                            name="email"
                                            type="email"
                                            autoComplete="email"
                                            margin="normal"
                                            // variant="outlined"
                                            onChange={handleChange}
                                            value={values.email}
                                          />
                                          {errors.email && touched.email && (
                                            <FormHelperText error>
                                              {errors.email}
                                            </FormHelperText>
                                          )}
                                        </div>

                                        <div className="assessment--phone--div">
                                          <MuiPhoneNumber
                                            fullWidth
                                            name="phone"
                                            // variant="outlined"
                                            disableAreaCodes={true}
                                            onBlur={handleChange}
                                            defaultCountry={"gb"}
                                            onChange={handleChange}
                                            countryCodeEditable={true}
                                            // label={"Mobile Phone Number"}
                                            value={values.phone}
                                          />
                                          {errors.phone && touched.phone && (
                                            <FormHelperText error>
                                              {errors.phone}
                                            </FormHelperText>
                                          )}
                                        </div>
                                      </CardBody>
                                      <div className={`${classes.textCenter} login--btn--div`}>
                                        <Button
                                          onClick={handleSubmit}
                                          color="gradient"
                                          variant="contained"                                         
                                        >
                                          {isSelfAssessmentLoading ? (
                                            <span className="btn-spinner" />
                                          ) : (
                                              "Start Assessment"
                                            )}
                                        </Button>
                                      </div>
                                    </>
                                  )}
                              </Formik>
                            </form>
                          </Card>
                        </GridItem>
                      </GridContainer>

                    </div>
                  </div>
                )
              }

            </div>
          </div>
        </Suspense>
      </>
    );
  }
}
SelfAssessment.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    selfAssessmentSuccess:
      state.selfAssessmentReducer &&
      state.selfAssessmentReducer.selfAssessmentSuccess,
    selfAssessmentError:
      state.selfAssessmentReducer &&
      state.selfAssessmentReducer.selfAssessmentError,
    selfAssessmentData:
      state.selfAssessmentReducer &&
      state.selfAssessmentReducer.selfAssessmentData,
    isSelfAssessmentLoading:
      state.selfAssessmentReducer &&
      state.selfAssessmentReducer.isSelfAssessmentLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      selfAssessment,
      selfAssessmentClearFlag,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withApollo(withStyles(styles)(SelfAssessment)));
