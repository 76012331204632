/* eslint-disable */
import React, { Component, Suspense, lazy } from "react";
import { PayPalButton } from "react-paypal-button-v2";
import PropTypes from "prop-types";
import { withApollo } from "react-apollo";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { isMobileOnly, isTablet } from "react-device-detect";
import { withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import StripeCheckout from "react-stripe-checkout";

const Footer = lazy(() => import("../../components/Footer/Footer"));
const Header = lazy(() => import("components/Header/Header.js"));
const HeaderLinks = lazy(() => import("components/Header/HeaderLinks"));
const GridContainer = lazy(() => import("components/Grid/GridContainer"));
const GridItem = lazy(() => import("components/Grid/GridItem"));
const Img = lazy(() => import("components/Image/Image.js"));
const Button = lazy(() => import("components/CustomButtons/Button.js"));

import { payment } from "../../redux/actions/OrderActions";
import MenergyLogo from "assets/img/favicon.png";
import paymentLeftImg from "../../assets/img/payment/online-payment1.jpg";
import Back from "../../assets/img/payment/back.svg";
import styles from "assets/jss/material-kit-pro-react/views/signupPageStyle.js";
import "./Payment.scss";

class Payment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: null,
      total: props.location.state && props.location.state.total,
      qty: props.location.state && props.location.state.qty,
      payment: "Paypal",
    };
  }

  onClickPayPal = () => {
    this.setState({
      payment: "Paypal",
    });
  };

  onClickStripe = () => {
    this.setState({
      payment: "Stripe",
    });
  };

  paymentSuccess = (details, data) => {
    const { qty } = this.state;
    const { payment, client } = this.props;
    const paymentData = {
      qty,
      productId: 2,
      response: JSON.stringify(details),
    };

    payment(paymentData, client);
    this.props.history.push("/order");
  };

  handleChange = (event, newValue) => {
    this.setState({
      value: newValue,
    });
  };

  onToken = (token) => {
    console.log(token, "token");
  };

  render() {
    const { classes } = this.props;
    const { value, total, payment } = this.state;
    return (
      <Suspense fallback={<div> Loading... </div>}>
        <div style={{ background: "#fff" }}>
          <Header
            absolute
            color="white"
            links={<HeaderLinks dropdownHoverColor="rose" />}
            // {...rest}
          />
          <div
            className={
              isMobileOnly
                ? "mobile--payment--main--section"
                : "payment--main--section"
            }
          >
            <div
              className={
                isTablet
                  ? "payment--tablet--gateway payment--gateway"
                  : "payment--gateway"
              }
            >
              <div className="payment--gateway--section">
                <div className={`${classes.paymentRoot} payment--section`}>
                  <div className="payment--left--div">
                    <Img src={paymentLeftImg} alt="img" />
                  </div>
                  <div className="payment--right--div">
                    <>
                      <div className="payment--btn--option--section">
                        <div className="payment--title--div">
                          <span className="payment--info--title">
                            Payment Information
                          </span>
                          <span className="payment--info--sub--title">
                            Please add your payment method to complete your
                            order and get your products as soon as possible.
                          </span>
                        </div>
                        <GridContainer justify="center">
                          <GridItem lg={2} xs={6} sm={4} md={4}>
                            <Button
                              color="gradient"
                              className="paypal--main--button"
                              fullWidth
                              onClick={this.onClickPayPal}
                            >
                              PayPal
                            </Button>
                          </GridItem>
                          <GridItem lg={2} xs={6} sm={4} md={4}>
                            <Button
                              color="gradient"
                              fullWidth
                              onClick={this.onClickStripe}
                              className="stripe--main--button"
                            >
                              Stripe
                            </Button>
                          </GridItem>
                        </GridContainer>
                        {payment === "Paypal" && (
                          <GridContainer
                            justify="center"
                            className="payment--buttons"
                          >
                            <GridItem lg={4} sm={8} md={8} xs={12}>
                              <PayPalButton
                                amount={total}
                                options={{
                                  clientId: "sb",
                                  currency: "EUR",
                                }}
                                onSuccess={(details, data) =>
                                  this.paymentSuccess(details, data)
                                }
                                onButtonReady={() =>
                                  this.setState({ showLoading: false })
                                }
                                catchError={(err) => console.log(err, "err")}
                              />
                            </GridItem>
                          </GridContainer>
                        )}

                        {payment === "Stripe" && (
                          <GridContainer
                            justify="center"
                            className="payment--buttons"
                          >
                            <GridItem lg={4} sm={4} md={4} xs={12}>
                              <StripeCheckout
                                amount={total}
                                description="COVID-19 Test Kit"
                                image={MenergyLogo}
                                locale="auto"
                                name="Menergy"
                                stripeKey="pk_test_51H1tzyIYcEIdRyAYFK9eUO5LRMCbNyexKzij6u07IRRhU2wFU9vew4o8MAUuK9jspl9qSjDgmdxfwc6m054QSSJw00tNygbE6E"
                                token={this.onToken}
                                zipCode
                              />
                            </GridItem>
                          </GridContainer>
                        )}
                      </div>
                    </>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </Suspense>
    );
  }
}

Payment.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      payment,
    },
    dispatch
  );
};

export default connect(
  null,
  mapDispatchToProps
)(withApollo(withStyles(styles)(Payment)));
