/* eslint-disable */
import React, { lazy, Suspense } from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";

const Header = lazy(() => import("components/Header/Header.js"));
const HeaderLinks = lazy(() => import("components/Header/HeaderLinks.js"));
const Parallax = lazy(() => import("components/Parallax/Parallax.js"));
const Footer = lazy(() => import("components/Footer/Footer.js"));
const SectionDescription = lazy(() =>
  import("views/PresentationPage/Sections/SectionDescription.js")
);
const SectionBenefits = lazy(() =>
  import("views/PresentationPage/Sections/SectionBenefits.js")
);
const SectionCards = lazy(() =>
  import("views/PresentationPage/Sections/SectionCards.js")
);
const SectionFreeDemo = lazy(() =>
  import("views/PresentationPage/Sections/SectionFreeDemo.js")
);
const SectionFeatures = lazy(() =>
  import("views/SectionsPage/Sections/SectionFeatures")
);
const SectionTestimonials = lazy(() =>
  import("views/SectionsPage/Sections/SectionTestimonials")
);
const SectionWhy = lazy(() =>
  import("views/PresentationPage/Sections/SectionWhy")
);
import "./PresentationPage.scss";
import presentationStyle from "assets/jss/material-kit-pro-react/views/presentationStyle.js";
import { Helmet } from "react-helmet";

const useStyles = makeStyles(presentationStyle);

export default function PresentationPage() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    const myId = window.location.hash.slice(1);
    const elem = document.getElementById(myId);
    if (elem) {
      elem.scrollIntoView();
    }
  });
  const classes = useStyles();
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Menergy | Home</title>
        {/* <link rel="canonical" href="https://www.thegaston.com/events" /> */}
        <meta
          name="description"
          content="Menergy provides medically supervised hormone replacement of testosterone deficiencies."
        />
        <meta
          name="keywords"
          content="Menergy, Test Kit, Covid-19, Allergy Test, Food Sensitivity Test, Vitamin Test, Thyroid Test, Hormonal Test"
        />
        <meta name="og:title" property="og:title" content="The Gaston" />
        <meta
          name="og:description"
          property="og:description"
          content="Menergy provides medically supervised hormone replacement of testosterone deficiencies."
        />
        <meta
          name="og:image"
          property="og:image"
          content="https://healthtest.nceptio.com/static/media/menergy.32d08b29.svg"
        />
      </Helmet>
      <Suspense fallback={<div> Loading... </div>}>
        <div>
          <Header
            links={<HeaderLinks dropdownHoverColor="info" />}
            fixed
            color="white"
            changeColorOnScroll={{
              height: 100,
              color: "white",
            }}
            showAdvertiseHeader
          />
          <Parallax
            image={require("assets/img/img2.jpg")}
            className={classes.parallax}
          />
          <div className={classNames(classes.main, classes.mainRaised)}>
            <SectionDescription />
            <SectionCards />
            <div id="ContactUs" className="home--section--why">
              <SectionWhy />
            </div>
            <SectionFeatures />
            <SectionBenefits />
            <SectionTestimonials />
            <div id="ContactUs">
              <SectionFreeDemo />
            </div>
            <Footer />
          </div>
        </div>
      </Suspense>
    </>
  );
}
