import {
  container,
  description,
  cardTitle,
  // blackColor,
  whiteColor,
  grayColor,
  secondaryDarkColor
} from "assets/jss/material-kit-pro-react.js";

const signupPageStyle = {
  description,
  cardTitle: {
    ...cardTitle,
    textAlign: "center !important",
    marginBottom: "0.75rem",
    color: "#455a64 !important",
    lineHeight: "80px",
    fontFamily: "inherit !important",
    "@media (max-width:768px)": {
      marginTop: '1.75rem',
    }
  },
  container: {
    ...container,
    zIndex: "4"
  },
  pageHeader: {
    color: whiteColor,
    border: "0",
    height: "100%",
    margin: "0",
    display: "flex!important",
    padding: "120px 0",
    position: "relative",
    minHeight: "100vh",
    alignItems: "center",
    // "&:before": {
    //   background: "rgba(" + hexToRgb(blackColor) + ", 0.5)"
    // },
    "&:before,&:after": {
      position: "absolute",
      zIndex: "1",
      width: "100%",
      height: "100%",
      display: "block",
      left: "0",
      top: "0",
      content: '""'
    }
  },
  form: {
    margin: "0"
  },
  cardHeader: {
    width: "auto",
    textAlign: "center"
  },
  socialLine: {
    marginTop: "1rem",
    textAlign: "center",
    padding: "0"
  },
  inputIconsColor: {
    color: grayColor[13]
  },
  textCenter: {
    textAlign: "center"
  },
  iconButtons: {
    marginRight: "3px !important",
    marginLeft: "3px !important"
  },
  block: {
    color: "inherit",
    padding: "0.9375rem",
    fontWeight: "500",
    fontSize: "12px",
    textTransform: "uppercase",
    borderRadius: "3px",
    textDecoration: "none",
    position: "relative",
    display: "block"
  },
  inlineBlock: {
    display: "inline-block",
    padding: "0px",
    width: "auto"
  },
  list: {
    marginBottom: "0",
    padding: "0",
    marginTop: "0"
  },
  left: {
    float: "left!important",
    display: "block",
    "&,& *,& *:hover,& *:focus": {
      color: whiteColor + "  !important"
    }
  },
  right: {
    padding: "15px 0",
    margin: "0",
    float: "right",
    "&,& *,& *:hover,& *:focus": {
      color: whiteColor + "  !important"
    }
  },
  icon: {
    width: "18px",
    height: "18px",
    top: "3px",
    position: "relative"
  },
  footer: {
    position: "absolute",
    width: "100%",
    background: "transparent",
    bottom: "0",
    color: whiteColor,
    zIndex: "2"
  },
  cardDiv: {
    boxShadow: "none !important",
    "@media (max-width: 768px)": {
      marginTop: 0
    }
  },
  socialButtons: {
    textAlign: "center",
    "& ul": {
      display: "inline",
      paddingLeft: "0px",
      "& li": {
        display: "inline-block"
      }
    }
  },
  loginMainDiv: {
    backgroundColor: whiteColor,
    minHeight: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: "125px"
  },
  divContent: {
    textAlign: "center",
    marginBottom: 10,
    color: secondaryDarkColor,
    "& span": {
      fontWeight: 500
    }
  },
  error: {
    color: "red",
    textAlign: "center"
  },
  loginPageContainer: {
     paddingTop: "25vh",
     "@media (max-width:768px)": {
        paddingTop: "5vh",
     } 
  },
  loginImage: {
     width: "100%", marginTop: "50px"
  }, 
};

export default signupPageStyle;
