/*eslint-disable*/
import React, { Component, lazy, Suspense } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/styles";
import { Button } from "@material-ui/core";
import "react-circular-progressbar/dist/styles.css";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";

const Header = lazy(() => import("components/Header/Header"));
const HeaderLinks = lazy(() => import("components/Header/HeaderLinks"));
const GridItem = lazy(() => import("components/Grid/GridItem"));
const GridContainer = lazy(() => import("components/Grid/GridContainer"));
const Card = lazy(() => import("components/Card/Card"));
const CardBody = lazy(() => import("components/Card/CardBody"));
const Footer = lazy(() => import("../../components/Footer/Footer"));

import img from "../../assets/img/testkit5.jpg";
import styles from "../../assets/jss/material-kit-pro-react/views/testResponseStyle";

class Response extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ksa: props.location.state ? props.location.state.data.score : 30,
      highKsa: true,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const { ksa } = this.state;
    if (ksa > 27) {
      this.setState({
        highKsa: true,
      });
    }
  }

  goToTestKitDetails = () => {
    this.props.history.push("/test-kit-details");
  };

  render() {
    const { classes } = this.props;
    const { ksa, highKsa } = this.state;
    return (
      <>
        <Suspense fallback={<div> Loading... </div>}>
          <div className={classes.mainDiv}>
            <Header
              absolute
              color="white"
              links={<HeaderLinks dropdownHoverColor="rose" />}
            />
            <div className={classes.divFlex}>
              <div className={classes.container}>
                <GridContainer justify="center">
                  <GridItem xs={12} sm={12} md={12}>
                    <Card className={classes.cardSignup}>
                      <CardBody>
                        <GridContainer justify="center">
                          <GridItem className={classes.divResultFlex}>
                            <div>
                              <CircularProgressbar
                                value={ksa}
                                text={`${ksa}`}
                                styles={buildStyles({
                                  pathColor: "#0097a7",
                                  textColor: "#0097a7",
                                })}
                              />
                            </div>
                            <div>
                              {highKsa ? (
                                <div className={classes.result}>
                                  <p className={classes.fontStyle}>
                                    Your score is above 27, you have
                                    testosterone deficiency.
                                  </p>
                                  <p className={classes.fontStyle}>
                                    Please purchase Initial Test Kit.
                                  </p>
                                </div>
                              ) : (
                                <div className={classes.divResult}>
                                  <p className={classes.fontStyle}>
                                    Your score is below 27, you do not have
                                    testosterone deficiency.
                                  </p>
                                  <p className={classes.fontStyle}>
                                    Thank you !
                                  </p>
                                  <Button
                                    variant="contained"
                                    href="/"
                                    className={classes.buttonFinish}
                                  >
                                    Finish
                                  </Button>
                                </div>
                              )}
                            </div>
                          </GridItem>
                        </GridContainer>
                      </CardBody>
                    </Card>
                  </GridItem>
                </GridContainer>

                {highKsa && (
                  <GridContainer>
                    <GridItem xs={12} sm={6} md={6}>
                      <div>
                        <img
                          src={img}
                          alt="testkit"
                          className={classes.testKitImage}
                        />
                      </div>
                    </GridItem>
                    <GridItem xs={12} sm={6} md={6}>
                      <Card plain className={classes.marginTop0}>
                        <CardBody plain>
                          <div>
                            <h3
                              className={
                                classes.marginTop0 +
                                " " +
                                classes.fontWeightBold
                              }
                            >
                              Measure Your Covid-29 Infection
                            </h3>
                            <p className={classes.kitDetails}>
                              This at home test quickly checks to see if you are
                              suffering from covid-19 infection or not.
                            </p>
                            <p>
                              <span className={classes.fontWeightBold}>
                                {" "}
                                Collection Method{" "}
                              </span>{" "}
                              <span className={classes.kitDetails}>
                                {" "}
                                Finger Prick Blood{" "}
                              </span>
                            </p>
                          </div>
                          <div className={classes.divInfo}>
                            <div className={classes.testKit}>
                              <h4 className={classes.cardCategory}>Test Kit</h4>
                              <p className={classes.divPrice}>
                                <small>&euro;</small> 65
                              </p>
                            </div>
                            <div className={classes.testKit}>
                              <Button
                                onClick={this.goToTestKitDetails}
                                size="medium"
                                variant="contained"
                                className={classes.buttonBuy}
                              >
                                BUY
                              </Button>
                              <Button
                                href="\"
                                size="medium"
                                variant="contained"
                                className={classes.buttonFinishPurchase}
                              >
                                Finish
                              </Button>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </GridItem>
                  </GridContainer>
                )}
              </div>
            </div>
            <Footer />
          </div>
        </Suspense>
      </>
    );
  }
}

Response.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Response);
